import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChartConfiguration, ChartData, Plugin } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as _ from 'lodash-es';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { ChartClickEvent } from 'src/app/shared/common/components/gsp-chart/gsp-chart';
import { FeaturesStreamsService } from 'src/app/shared/common/current-features/features-streams.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { TemplatesStoreService } from 'src/app/shared/common/current-templates/templates-store.service';
import { AccuracyUtils, UnitSystem } from 'src/app/shared/common/utility/accuracy-utils';
import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';

import { PostProcessingStatus } from '../../post-processing/options/post-processing-options.component';
import { ChartPanelStreamService, PreviousSelectedFeatures } from '../common/chart-panel-stream.service';
import { AccuracyPanelUtil, chartOptions } from './accuracy-panel.util';

@Component({
    selector: 'accuracy-panel',
    templateUrl: './accuracy-panel.component.html'
})
export class AccuracyPanelComponent implements OnInit, OnDestroy {
    isAccuracyPanelDisplayed$: Observable<boolean>;
    selectedFeatures$: BehaviorSubject<Feature[]>;
    positionsWithBetterAccuracy: Feature[];
    featuresOutsideThreshold: Feature[];
    previousSelectedFeatures: PreviousSelectedFeatures;
    filteredFeatureCount: number;
    disableBetterAccuracyLink = true;
    disableOutsideThresholdLink = true;

    chartOptions: ChartConfiguration['options'] = chartOptions;
    chartPosition = 0;

    chartData: ChartData<'bar'> = {
        datasets: [
            {
                data: [],
                backgroundColor: '#005f9e36',
                hoverBackgroundColor: '#005f9e',
                barPercentage: 1.24
            }
        ]
    };

    plugins: Plugin[] = [
        ChartDataLabels,
        {
            id: 'rotateTicks',
            // Add custom chart labels
            afterDraw: chart => {
                const xAxis = chart.scales.x;
                const tickDistance = xAxis.width / xAxis.ticks.length;
                const y = chart.height - 10;
                chart.ctx.fillStyle = '#666';
                chart.ctx.save();
                chart.ctx.translate(5, y);
                chart.ctx.textAlign = 'center';
                chart.ctx.fillText('0', 0, 0);
                chart.ctx.restore();
                xAxis.ticks.forEach((value, index) => {
                    // -4 so the last label doesn't get cut off
                    const x = tickDistance * (index + 1) - 4;
                    chart.ctx.save();
                    chart.ctx.translate(x, y);
                    chart.ctx.rotate(Math.PI / 4);
                    chart.ctx.textAlign = 'center';
                    chart.ctx.fillText(value.label as string, 0, 0);
                    chart.ctx.restore();
                });
            }
        },
        {
            // Custom plugin for hover events. The most recent (v4) chart.js 'onHover' hook only fires inside the graph axes
            // but we need to know if the labels are being hovered over. If cursor is hovering over bar - set cursor
            // to pointer. Otherwise set cursor to default and remove 'active' highlights from bars.
            id: 'mouseEventCatcher',
            beforeEvent: (chart, args, pluginOptions) => {
                const event = args.event;
                if (['mousemove', 'mouseout'].includes(event.type)) {
                    if (
                        chart.getElementsAtEventForMode(
                            event as unknown as Event,
                            'nearest',
                            { intersect: true },
                            false
                        ).length
                    ) {
                        chart.canvas.style.cursor = 'pointer';
                    } else {
                        chart.canvas.style.cursor = 'default';
                        chart.setActiveElements([]);
                    }
                }
            }
        }
    ];

    updateChartStream = new Subject<void>();

    private stepsDict: { [key: string]: { accuracy: number; label: string } };
    private destroyed = new Subject<void>();
    private unitSystem: UnitSystem;
    private currentHighestCount: number;

    constructor(
        private chartPanelStreamService: ChartPanelStreamService,
        private featuresStreams: FeaturesStreamsService,
        private projectStream: ProjectStreamService,
        private templateStore: TemplatesStoreService
    ) {
        this.isAccuracyPanelDisplayed$ = this.chartPanelStreamService.isAccuracyPanelDisplayedStream;
        this.selectedFeatures$ = this.featuresStreams.selectedFeaturesStream;
    }

    async ngOnInit(): Promise<void> {
        await this.getUnitsAndDict();
        this.initSubscriptions();
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    private async getUnitsAndDict(): Promise<void> {
        const { settings } = await this.projectStream.getProjectWithUpdatedSettings();
        this.unitSystem =
            settings.unitSettings.unitsystem === UnitSystem.METRIC ? UnitSystem.METRIC : UnitSystem.IMPERIAL;
        this.stepsDict = AccuracyPanelUtil.getStepsDict(this.unitSystem);
        this.chartData.labels = Object.values(this.stepsDict).map(step => step.label);
    }

    private initSubscriptions(): void {
        this.isAccuracyPanelDisplayed$.pipe(takeUntil(this.destroyed)).subscribe(isAccuracyPanelDisplayed => {
            if (this.previousSelectedFeatures && !isAccuracyPanelDisplayed) {
                this.chartPanelStreamService.previousSelectedFeatures = null;
            }
        });
        this.selectedFeatures$
            .pipe(
                takeUntil(this.destroyed),
                map(selectedFeatures =>
                    selectedFeatures.filter(
                        feature =>
                            !GeneralUtils.isNullUndefinedOrNaN(
                                AccuracyUtils.getRoundedHorizontalAccuracyFromMetadata(
                                    feature.metadata,
                                    feature.geometryType
                                )
                            ) && !feature.properties?.correctionstatus?.toLowerCase().includes('digitized')
                    )
                )
            )
            .subscribe(filteredFeatures => {
                if (!filteredFeatures.length && this.chartPanelStreamService.isAccuracyPanelDisplayed) {
                    this.chartPanelStreamService.isAccuracyPanelDisplayed = false;
                    return;
                }
                this.filteredFeatureCount = filteredFeatures.length;
                this.updateChartData(filteredFeatures);
                this.updateAccuracyThresholds(filteredFeatures);
                this.setLinkDisabledValues(filteredFeatures);
            });
        this.chartPanelStreamService.previousSelectedFeaturesStream
            .pipe(takeUntil(this.destroyed), distinctUntilChanged(_.isEqual))
            .subscribe((previousSelectedFeatures: PreviousSelectedFeatures) => {
                this.previousSelectedFeatures = previousSelectedFeatures;
            });

        this.chartPanelStreamService.panelOffsetPosition.pipe(takeUntil(this.destroyed)).subscribe(panelPosition => {
            this.chartPosition = panelPosition;
        });
    }

    private updateChartData(filteredFeatures: Feature[]): void {
        this.chartData.datasets[0].data = AccuracyPanelUtil.getChartData(filteredFeatures, this.stepsDict);
        // Add 20% buffer to top of chart
        const highestCount = Math.max(...(this.chartData.datasets[0].data as number[]));
        if (highestCount !== this.currentHighestCount && !this.previousSelectedFeatures) {
            this.currentHighestCount = highestCount;
            const updatedChartOptions = _.cloneDeep(this.chartOptions);
            updatedChartOptions.scales.y.max = highestCount * 1.2;
            // Overwrite class member so changes are detected
            this.chartOptions = updatedChartOptions;
        }
        this.updateChartStream.next(null);
    }

    private updateAccuracyThresholds(filteredFeatures: Feature[]): void {
        this.positionsWithBetterAccuracy = [];
        this.featuresOutsideThreshold = [];
        const currentTemplates = this.templateStore.getCurrentMapWorkspaceTemplates();
        filteredFeatures.forEach(feature => {
            // A feature can have the status of PROCESSED but that doesn't mean that any positions were corrected
            // by post-processing - processing_postProcessedCount provides the count of positions corrected by
            // post-processing. Check that there is at least 1.
            if (
                feature.metadata['processing_postprocessedStatus'] === PostProcessingStatus.PROCESSED &&
                feature.metadata['processing_postProcessedCount']
            ) {
                if (
                    !feature.metadata.common_unprocessed ||
                    _.isEmpty(feature.metadata.common_unprocessed) ||
                    AccuracyUtils.getRoundedHorizontalAccuracyFromMetadata(feature.metadata, feature.geometryType) <
                        AccuracyUtils.getRoundedHorizontalAccuracyFromMetadata(
                            feature.metadata.common_unprocessed.metadata,
                            feature.geometryType
                        )
                ) {
                    this.positionsWithBetterAccuracy.push(feature);
                }
            }
            const template = currentTemplates[feature.templateId];
            if (
                template?.requireMinimumAccuracy &&
                AccuracyUtils.getRoundedHorizontalAccuracyFromMetadata(feature.metadata, feature.geometryType) >
                    template?.accuracyLimit
            ) {
                this.featuresOutsideThreshold.push(feature);
            }
        });
    }

    private setLinkDisabledValues(filteredFeatures: Feature[]): void {
        this.disableBetterAccuracyLink = !_.differenceWith(
            filteredFeatures,
            this.positionsWithBetterAccuracy,
            _.isEqual
        ).length;
        this.disableOutsideThresholdLink = !_.differenceWith(filteredFeatures, this.featuresOutsideThreshold, _.isEqual)
            .length;
    }

    private loadNewFeatures(newFeatures: Feature[], previousFeatures?: Feature[]): void {
        if (!previousFeatures) {
            previousFeatures = this.selectedFeatures$.getValue();
        }
        this.chartPanelStreamService.previousSelectedFeatures = this.previousSelectedFeatures?.features
            ? { features: previousFeatures, previousSelection: this.previousSelectedFeatures }
            : { features: previousFeatures };
        this.featuresStreams.replaceOnSelectedFeatures(newFeatures);
    }

    onChartClick(data: ChartClickEvent): void {
        const index: number = data.active[0]?.index;
        if (GeneralUtils.isNullUndefinedOrNaN(index)) {
            return;
        }
        const [greaterThanAccuracy, lessThanAccuracy] = AccuracyPanelUtil.getAccuracyRangeFromIndex(
            index,
            this.stepsDict
        );
        const previousFeatures = this.selectedFeatures$.getValue();
        const newFeatures = previousFeatures.filter(
            feature =>
                (!lessThanAccuracy ||
                    AccuracyUtils.getRoundedHorizontalAccuracyFromMetadata(feature.metadata, feature.geometryType) <=
                        lessThanAccuracy) &&
                (!greaterThanAccuracy ||
                    AccuracyUtils.getRoundedHorizontalAccuracyFromMetadata(feature.metadata, feature.geometryType) >
                        greaterThanAccuracy)
        );
        if (newFeatures.length !== previousFeatures.length) {
            this.loadNewFeatures(newFeatures, previousFeatures);
        }
    }

    goBack(): void {
        this.featuresStreams.replaceOnSelectedFeatures(this.previousSelectedFeatures.features);
        this.chartPanelStreamService.previousSelectedFeatures = this.previousSelectedFeatures.previousSelection
            ? this.previousSelectedFeatures.previousSelection
            : null;
    }
}
