<gsp-popup
    class="export-panel"
    [heading]="'TC.Common.ExportData' | translate"
    [size]="ModalSize.XXlarge"
    [showClose]="true"
    (closed)="closePanel()"
    id="export-popup"
>
    <ng-container transclude-popup-content>
        <loading *ngIf="isBusySettingupTheExport" class="loading-align-center"></loading>
        <div class="flex-row-new align-start" *ngIf="!isBusySettingupTheExport">
            <mat-tab-group (selectedTabChange)="selectedTab($event)">
                <mat-tab label="{{ 'TC.Common.Export' | translate }}">
                    <ng-form #exportForm="ngForm" class="flex7-new">
                        <custom-scroll>
                            <div class="export-form-options">
                                <div class="mb-1">
                                    {{ 'MapViewer_Export_Notification' | translate }}
                                </div>

                                <!-- Email Address -->
                                <div class="export-form-group">
                                    <div class="export-form-group-label">
                                        {{ 'TC.Common.EmailAddress' | translate }}
                                    </div>

                                    <div class="export-form-group-input">
                                        <gsp-text
                                            #exportEmail="ngModel"
                                            [type]="GspTextType.EMAIL"
                                            id="exportEmail"
                                            inputClass="export-form-group-input"
                                            name="exportEmail"
                                            [(ngModel)]="currentExport.emailAddress"
                                            required="true"
                                            length="60"
                                            errorTooltipMsg="{{ getErrorText(exportEmail.errors) }}"
                                        ></gsp-text>
                                    </div>
                                </div>

                                <!-- Export File Name -->
                                <div class="export-form-group">
                                    <div class="export-form-group-label" translate="TC.Common.FileName"></div>
                                    <div class="export-form-group-input">
                                        <gsp-text
                                            #exportName="ngModel"
                                            [type]="GspTextType.GENERIC"
                                            id="exportName"
                                            inputClass="export-form-group-input"
                                            name="exportName"
                                            [(ngModel)]="currentExport.name"
                                            required="true"
                                            length="60"
                                            errorTooltipMsg="{{ getErrorText(exportName.errors) }}"
                                        ></gsp-text>
                                    </div>
                                </div>

                                <!-- Export Format -->
                                <div class="export-form-group mt-2">
                                    <div class="export-form-group-label" translate="TC.Common.Format"></div>
                                    <div class="export-form-group-input">
                                        <gsp-dropdown
                                            name="exportFormat"
                                            [options]="formatOptions"
                                            [(ngModel)]="selectedFormat"
                                            textField="displayName"
                                            id="exportFormat"
                                        ></gsp-dropdown>
                                    </div>
                                </div>
                                <div
                                    class="export-form-group"
                                    *ngIf="!hasAuthorizedCadEntitlement && isCadFormatSelected()"
                                >
                                    <div class="export-form-group-label"></div>
                                    <div class="export-form-group-input">
                                        <gsp-notification [type]="NotificationType.Error" class="notification">
                                            <div
                                                [innerHTML]="
                                                    'MapViewer.Export.HelpText.RequiredTerraOfficeLicenseForCadExports'
                                                        | translate
                                                "
                                            ></div>
                                        </gsp-notification>
                                    </div>
                                </div>

                                <!-- PDF Export options -->
                                <ng-container *ngIf="isPDFExport">
                                    <div
                                        class="export-form-group"
                                        *ngIf="
                                            (!canExportToPDF || (canExportToPDF && !hasPDFTemplateForPDFExport)) &&
                                            !isBusyGettingPDFExportAttributes &&
                                            PDFExportError
                                        "
                                    >
                                        <div class="export-form-group-label"></div>
                                        <div class="export-form-group-input">
                                            <gsp-notification [type]="NotificationType.Error" id="pdf-export-error"
                                                ><div>{{ PDFExportError }}</div>
                                            </gsp-notification>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="canExportToPDF">
                                        <div class="export-form-group">
                                            <div class="export-form-group-label">
                                                {{ 'MapViewer_Export_PDF_Template' | translate }}
                                                <gsp-help-text
                                                    [text]="'MapViewer.Export.HelpText.PDFTemplate' | translate"
                                                ></gsp-help-text>
                                            </div>

                                            <div class="export-form-group-input">
                                                <gsp-dropdown
                                                    name="template"
                                                    [options]="PDFTemplates"
                                                    [(ngModel)]="selectedPDFTemplate"
                                                    textField="name"
                                                    idField="id"
                                                    [ngDisabled]="!hasPDFTemplateForPDFExport"
                                                    id="pdf-templates-selection"
                                                ></gsp-dropdown>
                                            </div>
                                        </div>
                                        <div class="export-form-group">
                                            <div class="export-form-group-label">
                                                {{ 'MapViewer_Export_PDF_FileName' | translate
                                                }}<gsp-help-text
                                                    [text]="'MapViewer.Export.HelpText.PDFFileName' | translate"
                                                ></gsp-help-text>
                                            </div>
                                            <div class="export-form-group-input">
                                                <gsp-dropdown
                                                    name="templateName"
                                                    [options]="names"
                                                    [(ngModel)]="currentExport.pdfExportNameFieldName"
                                                    textField="displayName"
                                                    idField="id"
                                                    [ngDisabled]="!hasPDFTemplateForPDFExport"
                                                    id="templateName"
                                                ></gsp-dropdown>
                                            </div>
                                        </div>
                                        <div class="export-form-group">
                                            <div class="export-form-group-label"></div>
                                            <div class="export-form-group-input">
                                                <gsp-notification [type]="NotificationType.Info"
                                                    ><div>
                                                        {{ 'MapViewer_Export_PDF_FileNameHint' | translate }}
                                                    </div></gsp-notification
                                                >
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>

                                <!-- Export Filter -->
                                <div class="export-form-group mt-2">
                                    <div class="export-form-group-label">
                                        {{ 'TC.Common.ExportData' | translate }}
                                    </div>
                                    <div class="export-form-group-input">
                                        <gsp-dropdown
                                            name="exportFilter"
                                            [options]="currentExport.exportFilters"
                                            [(ngModel)]="selectedFilter"
                                            textField="displayName"
                                            id="exportFilter"
                                        >
                                        </gsp-dropdown>
                                    </div>
                                </div>

                                <!-- Export Filter Description -->
                                <div class="export-form-group">
                                    <div class="export-form-group-label"></div>
                                    <div class="export-form-group-input">
                                        <gsp-notification [type]="NotificationType.Info" class="mb-1">
                                            <div>
                                                {{
                                                    getExportFilterOptionInfo(currentExport.exportScope).description
                                                        | translate
                                                }}
                                            </div>
                                        </gsp-notification>
                                    </div>
                                </div>

                                <!-- Image Options -->
                                <div class="export-form-group">
                                    <div class="export-form-group-label" translate="image"></div>
                                    <div
                                        class="export-form-group-input"
                                        [title]="
                                            isPDFExport
                                                ? 'You cant export images if the export format selected is PDF'
                                                : ''
                                        "
                                    >
                                        <gsp-dropdown
                                            name="imageOption"
                                            [options]="imageOptions"
                                            [(ngModel)]="currentExport.imagesAsHyperlinks"
                                            [ngDisabled]="isPDFExport"
                                            textField="displayName"
                                            id="imageOption"
                                        ></gsp-dropdown>
                                    </div>
                                </div>

                                <!-- Bulk set form tags -->
                                <div class="export-form-group">
                                    <div class="export-form-group-label" translate="TCW_Tags"></div>
                                    <div class="export-form-group-input">
                                        <chip-filter
                                            class="tag-input-alternative tag-bulk-set"
                                            [inputClass]="'tag-input-form'"
                                            [(selectedChips)]="currentExport.tags"
                                            [allChips]="availableFeatureTags"
                                            [restrictedChips]="restrictedTags"
                                            [name]="tags"
                                            [placeholder]="'TC.Common.Search' | translate"
                                            [multiInputMode]="true"
                                            (addChipEvent)="addFormTag($event)"
                                            (removeChipEvent)="removeFormTag($event)"
                                        ></chip-filter>
                                    </div>
                                </div>

                                <!-- Export Summary -->
                                <div class="export-form-summary-section" *ngIf="summary">
                                    <div class="export-count">{{ summary }}</div>
                                </div>

                                <!-- Tag as Exported -->
                                <gsp-checkbox
                                    [(value)]="currentExport.markAsExported"
                                    [id]="'exportTag'"
                                    [label]="'TCS.Export.AddExportedTag' | translate"
                                ></gsp-checkbox>

                                <!-- Export all form attributes -->
                                <gsp-checkbox
                                    [(value)]="currentExport.exportAllFormAttributes"
                                    [helpText]="'MapViewer.Export.ExportAllFormAttributes.Description' | translate"
                                    [id]="'exportAllFormAttributes'"
                                    [label]="'MapViewer.Export.ExportAllFormAttributes.Label' | translate"
                                ></gsp-checkbox>

                                <!-- Export Coded Choice fields using Choice value -->
                                <gsp-checkbox
                                    [(value)]="currentExport.useCodedChoiceDescription"
                                    [helpText]="'MapViewer.Export.ExportCodedChoiceDescription.Description' | translate"
                                    [id]="'useCodedChoiceDescription'"
                                    [label]="'MapViewer.Export.ExportCodedChoiceDescription.Label' | translate"
                                ></gsp-checkbox>
                            </div>
                        </custom-scroll>
                    </ng-form>

                    <!-- Workspace Coordinate System details Section -->
                    <div class="flex5-new pl-0">
                        <workspace-coordinate-system-details
                            [(workspace)]="exportMapWorkspace"
                            [canEditHeight]="canEditHeight"
                            [warningMessageOnHeightChange]="'MapViewer.Export.HeightChange.Warning' | translate"
                            [subtitle]="'MapViewer_Export_Coordinate_System' | translate"
                            (isLoading)="onWorkspaceCoordinateSystemDetailsLoading($event)"
                            *ngIf="exportMapWorkspace"
                        ></workspace-coordinate-system-details>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span
                            >{{ 'TC.Common.RecentExports' | translate }}
                            <gsp-help-text [text]="'Displays the last 25 valid exports'"></gsp-help-text>
                        </span>
                    </ng-template>
                    <ng-template matTabContent>
                        <recent-exports [exportMapWorkspaceId]="exportMapWorkspaceId"></recent-exports>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </ng-container>
    <ng-container transclude-popup-footer>
        <gsp-button
            [hidden]="!showCancelDone"
            [asyncAction]="exportButtonAction"
            [type]="ButtonType.Primary"
            [disabled]="disableExport"
            text="{{ 'TC.Common.Export' | translate }}"
            [iconClass]=""
        ></gsp-button>
        <gsp-button
            [hidden]="!showCancelDone"
            (action)="closePanel()"
            [type]="ButtonType.Default"
            text="{{ 'TC.Common.Cancel' | translate }}"
            [iconClass]=""
        ></gsp-button>
    </ng-container>
</gsp-popup>
