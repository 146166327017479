import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil, throttleTime } from 'rxjs/operators';

@Component({
    selector: 'custom-scroll',
    template: `
        <ng-scrollbar
            #ngScrollbar
            class="scroll-container"
            [ngClass]="{
                'scroll-shadow-top': (scrollPosition | async) > 0 && (isScrollVisible | async) === true,
                'scroll-shadow-end': (scrollPosition | async) < scrollBottom - 1 && (isScrollVisible | async) === true,
                'scroll-active': isScrollActive
            }"
            [autoHeightDisabled]="autoHeightDisable"
            [track]="scrollDirection"
            [sensorDebounce]="1"
            (updated)="scrollViewportUpdated()"
            trackClass="scrollbar"
            thumbClass="scrollbar-thumb"
            visibility="hover"
        >
            <ng-content></ng-content
        ></ng-scrollbar>
    `
})
export class CustomScrollbarComponent implements AfterViewInit, OnDestroy {
    @ViewChild(NgScrollbar) scrollable: NgScrollbar;

    @Input()
    public set triggerScrollToTop(value: boolean) {
        if (value) {
            this.scrollToTop();
        }
    }

    @Input()
    public set triggerScrollToLeft(value: boolean) {
        if (value) {
            this.scrollToLeft();
        }
    }

    @Input()
    public set triggerScrollToBottom(value: boolean) {
        if (value) {
            this.scrollToBottom();
        }
    }

    @Input()
    public isPageLoading = false;

    @Input()
    scrollDirection = 'vertical';

    @Input()
    autoHeightDisable = false;

    @Input()
    infiniteScroll = false;

    @Output()
    public loadNextPage: EventEmitter<null> = new EventEmitter();

    @Output()
    public viewportUpdated: EventEmitter<null> = new EventEmitter();

    scrollPosition = new Subject<number>();
    isScrollVisible = new Subject<boolean>();

    isScrollActive = false;
    scrollBottom: number;

    private destroyed$ = new Subject<void>();

    constructor(private zone: NgZone, private cdr: ChangeDetectorRef, private renderer: Renderer2) {}

    ngAfterViewInit() {
        // Showhide scroll shadow on top/bottom of scroll container
        this.scrollable.verticalScrolled.pipe(takeUntil(this.destroyed$)).subscribe((e: Event) => {
            this.zone.run(() => {
                const targetElement = e.target as HTMLElement;
                this.scrollBottom = Math.round(targetElement.scrollHeight - targetElement.clientHeight);
                this.scrollPosition.next(Math.round(targetElement.scrollTop));
                this.isScrollVisible.next(true);
                this.checkForInfiniteScroll(targetElement);
            });
        });

        let scrollTimer: ReturnType<typeof setTimeout>;
        this.scrollable.scrolled.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.isScrollActive = true;
            // Hide scroll track after 1s of inactivity
            clearTimeout(scrollTimer);
            scrollTimer = setTimeout(() => {
                this.isScrollActive = false;
            }, 1000);

            this.hideOverlappedElements('.overlap-dropdown-menu');
            this.hideOverlappedElements('.pika-single');
            this.hideOverlappedElements('.mat-mdc-autocomplete-panel');
        });

        this.scrollable.updated.pipe(takeUntil(this.destroyed$), throttleTime(200)).subscribe(() => {
            // Prevent scroll shadows from persisting after scroll is hidden due to change in overflow state
            if (!this.scrollable.state.isVerticallyScrollable) {
                this.isScrollVisible.next(false);
            }
            this.cdr.detectChanges();
        });
    }

    private hideOverlappedElements(className: string) {
        // Get a reference to the elements to remove
        const elements = document.body.querySelectorAll(className);

        elements.forEach((element: any) => {
            // hide element

            switch (className) {
                case '.mat-mdc-autocomplete-panel':
                    this.renderer.setStyle(element, 'opacity', '0');
                    break;
                default:
                    this.renderer.setStyle(element, 'position', 'static');
                    break;
            }
        });
    }

    private scrollToTop(): void {
        this.scrollable.scrollTo({ top: 0 });
    }

    private scrollToLeft(): void {
        this.scrollable.scrollTo({ left: 0 });
    }

    private scrollToBottom(): void {
        this.scrollable.scrollTo({ bottom: 0 });
    }

    public onLoadNextPage(): void {
        if (!this.isPageLoading) {
            this.loadNextPage.emit();
        }
    }

    public scrollViewportUpdated() {
        if (!this.isPageLoading) {
            this.viewportUpdated.emit();
        }
    }

    private checkForInfiniteScroll(targetElement: HTMLElement) {
        const scrollBottom = targetElement.scrollTop + targetElement.clientHeight;
        if (this.infiniteScroll && scrollBottom >= targetElement.scrollHeight - 50) {
            this.onLoadNextPage();
        }
    }

    ngOnDestroy() {
        this.destroyed$.next(null);
    }
}
