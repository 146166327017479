import { CdkDropList } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { Application } from 'src/app/shared/map-data-services/application';
import { AutoFieldModelType, FieldType, LayoutFieldType } from 'src/app/shared/template-services/field';
import { FieldsStoreService } from 'src/app/shared/template-services/field/fields-store.service';
import { Rule } from 'src/app/shared/template-services/rule/rule';
import { Template } from 'src/app/shared/template-services/template';

import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { GeometryTypes } from 'src/app/shared/common/utility/geometry-utils';
import { TemplateService } from 'src/app/shared/template-services/template.service';
import { ConnectedDropListService } from '../connected-drop-list.service';
import { RuleSelectionStreamService } from '../rules-panel/rule-selection-stream.service';

export enum GroupedFieldListKey {
    AutoFields = 'autofields',
    Data = 'data',
    Images = 'images',
    Layout = 'layout'
}

export type GroupedFieldList = {
    [key in GroupedFieldListKey]: GroupedFieldListItem;
};

export interface GroupedFieldListItem {
    collapsable: boolean;
    groupName: string;
    items: { [key: string]: any };
}

@Component({
    selector: 'field-panel',
    templateUrl: './field-panel.component.html'
})
export class FieldPanelComponent implements OnInit, OnDestroy {
    private destroyed = new Subject<void>();

    @Input()
    template: Template;

    @Input()
    templateEditorConfig: { tabs: { fields: { fieldsGroup: any[]; enableFieldConditions: { [key: string]: any } } } };

    @Input()
    application: Application;

    connectedLists$: Observable<CdkDropList[]>;

    @ViewChildren(CdkDropList)
    dropLists: QueryList<CdkDropList>;

    addAllButtonDisabled = false;
    groupedFieldList: GroupedFieldList = {} as GroupedFieldList;
    collapsed = true;
    selectedRule: Rule;
    fieldList: { [key: string]: any };
    ButtonType = ButtonType;
    GroupedFieldListKey = GroupedFieldListKey;

    constructor(
        private connectedDropLists: ConnectedDropListService,
        private ruleSelectionStream: RuleSelectionStreamService,
        private fieldsStore: FieldsStoreService,
        private templateService: TemplateService,
        private translate: TranslationService
    ) {
        this.ruleSelectionStream.ruleSelectionStream.pipe(takeUntil(this.destroyed)).subscribe(selectedRule => {
            this.selectedRule = selectedRule;
        });

        this.connectedLists$ = this.connectedDropLists.allLists$;
    }

    ngOnInit(): void {
        this.fieldsStore.getFieldsMap(this.application.name, this.application.category).then(fieldsMap => {
            this.templateEditorConfig.tabs.fields.fieldsGroup.forEach(
                (fieldGroup: { id: GroupedFieldListKey; label: string; fields: string[] }) => {
                    this.groupedFieldList[fieldGroup.id] = {} as GroupedFieldListItem;
                    this.groupedFieldList[fieldGroup.id].collapsable = false;
                    this.groupedFieldList[fieldGroup.id].groupName = fieldGroup.label;
                    this.groupedFieldList[fieldGroup.id].items = {};

                    fieldGroup.fields.map((fieldInGroup: string) => {
                        let selectedField = fieldsMap[fieldInGroup];
                        if (selectedField) {
                            let fieldDetail = {
                                type: selectedField.fieldType,
                                name: selectedField.application.layoutSchema?.label,
                                iconClass: selectedField.application.layoutSchema?.iconClass,
                                collapsable: selectedField.application.layoutSchema?.collapsable,
                                representsMultipleFields:
                                    selectedField.application.layoutSchema?.representsMultipleFields
                            };
                            this.groupedFieldList[fieldGroup.id].collapsable =
                                this.groupedFieldList[fieldGroup.id].collapsable ||
                                fieldsMap[fieldInGroup].application.layoutSchema?.collapsable;
                            this.groupedFieldList[fieldGroup.id].items[fieldInGroup] = fieldDetail;
                        }
                    });
                }
            );

            this.fieldList = CloneUtils.cloneDeep(this.groupedFieldList);
        });

        this.template.findIncompleteRule('Fields', this.selectedRule);
    }

    noEntry(): boolean {
        return false;
    }

    addFieldToTemplate(field: {
        type: FieldType | LayoutFieldType | AutoFieldModelType;
        subType: string;
        representsMultipleFields: boolean;
        allAutoFieldSection?: boolean;
    }): void {
        if (!this.disableField(field)) {
            if (field.type === FieldType.Sensor) {
                this.fieldsStore.currentApplication.next(this.application);
                this.fieldsStore.currentTemplate.next(this.template);
                this.fieldsStore.draggedSensorDataStream.next(null);
                this.fieldsStore.openPopupTo('template/editor/sensor-options');
                return;
            }

            if (field.representsMultipleFields) {
                this.fieldsStore.getFieldsMap(this.application.name, this.application.category).then(fieldsMap => {
                    Object.values(fieldsMap).forEach(fieldDef => {
                        // Adds XYZ fields to template
                        if (fieldDef.application.layoutSchema.representedByFieldType === field.type) {
                            this.template.insertNewField({
                                templateService: this.templateService,
                                fieldsStore: this.fieldsStore,
                                translate: this.translate,
                                application: this.application,
                                fieldType: fieldDef.fieldType,
                                fieldSubType: null,
                                allAutoFieldSection: field.allAutoFieldSection
                            });
                        }
                    });
                });
            } else {
                this.template.insertNewField({
                    templateService: this.templateService,
                    fieldsStore: this.fieldsStore,
                    translate: this.translate,
                    application: this.application,
                    fieldType: field.type,
                    fieldSubType: field.subType,
                    allAutoFieldSection: field.allAutoFieldSection
                });
            }
            if (field.type === LayoutFieldType.PageHeader) {
                this.checkAndInsertTopPageHeader();
            }
        }
    }

    addAllAutoFieldsToTemplate(fieldListItem: GroupedFieldListItem): void {
        if (!this.addAllButtonDisabled) {
            this.addAllButtonDisabled = true;
            // Add Page Header
            this.addFieldToTemplate({
                type: LayoutFieldType.PageHeader,
                subType: null,
                representsMultipleFields: false,
                allAutoFieldSection: true
            });

            this.addAutoFieldsBasedOnGeometry(this.template.geometryType, fieldListItem);

            // make sure dom loading is complete before scrolling
            setTimeout(() => {
                const pageHeaders = document.querySelectorAll('field-item .pageheader') as NodeListOf<HTMLElement>;
                if (pageHeaders.length) {
                    const autoFieldPageHeader = pageHeaders[pageHeaders.length - 1] as HTMLElement;
                    // scroll to autofield page header
                    autoFieldPageHeader.scrollIntoView({ behavior: 'smooth' });

                    // click on the header to expand edit field
                    (autoFieldPageHeader.parentElement.querySelector('.editor-field-header') as HTMLElement).click();
                }
            }, 0);

            this.enableAddAllButtonWithDelay(1000);
        }
    }

    toggleCollapse(): void {
        this.collapsed = !this.collapsed;
    }

    useFieldOrder(): number {
        // sort function for keyValue pipe.  returning 0 means it will use field order
        return 0;
    }

    private checkAndInsertTopPageHeader(): void {
        if (this.template.fields.length && this.template.fields[0].type !== LayoutFieldType.PageHeader) {
            this.template.insertNewField({
                templateService: this.templateService,
                fieldsStore: this.fieldsStore,
                translate: this.translate,
                application: this.application,
                fieldType: LayoutFieldType.PageHeader,
                fieldSubType: LayoutFieldType.PageHeader,
                position: [0],
                groupId: null,
                sequenceIncreaseValue: 1
            });
        }
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    includeField(field: { type: string; collapsable: any }): boolean {
        let enableFieldCondition = this.templateEditorConfig.tabs.fields.enableFieldConditions[field.type];
        return (
            (!field.collapsable || !this.collapsed) &&
            (!enableFieldCondition ||
                this.template[enableFieldCondition.condition.field as keyof Template] ===
                    enableFieldCondition.condition.value)
        );
    }

    disableField(field: { type: string; subType?: string }): boolean {
        if (this.template.lockedSchema) {
            // this will disable creation inside a nested fields from panel
            if (field.type === LayoutFieldType.PageHeader || field.type === LayoutFieldType.Group) {
                return false;
            }

            return true;
        }
        return false;
    }

    private enableAddAllButtonWithDelay(milliseconds: number): void {
        setTimeout(() => {
            this.addAllButtonDisabled = false;
        }, milliseconds);
    }

    private addAutoFieldsBasedOnGeometry(
        selectedGeometryType: GeometryTypes,
        fieldListItem: GroupedFieldListItem
    ): void {
        const fieldConditions = this.templateEditorConfig.tabs.fields.enableFieldConditions;
        const fieldTypesToExclude = Object.entries(fieldConditions)
            .filter(([_, condition]) => condition.condition.value !== selectedGeometryType)
            .map(([fieldType, _]) => fieldType as AutoFieldModelType);

        Object.entries(fieldListItem.items).forEach(([key, item]) => {
            const autoFieldType = key as AutoFieldModelType;
            if (!fieldTypesToExclude.includes(autoFieldType)) {
                this.addFieldToTemplate({
                    type: item.type,
                    subType: item.subType,
                    representsMultipleFields: item.representsMultipleFields
                });
            }
        });
    }
}
