import { Directive, ElementRef, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { UserSettingsStreamService } from 'src/app/shared/user/user-settings-stream.service';
import { ChartPanelStreamService } from '../common/chart-panel-stream.service';

@Directive({
    selector: '[resizablePanel]'
})
export class ResizablePanelDirective implements OnInit {
    private startX: number;
    private startWidth: number;
    private resizeHandle: HTMLElement;

    @Output() resizeComplete = new EventEmitter<void>();

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private chartPanelStreamService: ChartPanelStreamService,
        private userSettingsStreamService: UserSettingsStreamService
    ) {
        this.renderer.addClass(this.el.nativeElement, 'resizable-panel');
    }

    ngOnInit(): void {
        const panelWidth = this.userSettingsStreamService.getCurrentUserSettings().lastPanelWidth || '320px';

        this.renderer.setStyle(this.el.nativeElement, 'width', panelWidth);
        this.offsetChartPanelPosition();

        this.addResizeHandleElement();
    }

    private addResizeHandleElement(): void {
        this.resizeHandle = this.renderer.createElement('div');
        this.renderer.addClass(this.resizeHandle, 'resize-handle');
        this.renderer.setStyle(this.resizeHandle, 'cursor', 'ew-resize');

        // Resize handle icon
        const resizeHandleIcon = this.renderer.createElement('i');
        this.renderer.addClass(resizeHandleIcon, 'icon-resize-handle-horizontal');
        this.renderer.addClass(resizeHandleIcon, 'i24');

        this.renderer.appendChild(this.resizeHandle, resizeHandleIcon);
        this.renderer.insertBefore(this.el.nativeElement, this.resizeHandle, this.el.nativeElement.firstChild);
        this.offsetChartPanelPosition();

        // Listen for mousedown events on the resizeHandle element
        this.resizeHandle.addEventListener('mousedown', this.onMouseDown.bind(this));
    }

    private onMouseDown(event: MouseEvent): void {
        this.startX = event.clientX;
        this.startWidth = this.el.nativeElement.offsetWidth;
        this.addMouseEventListeners();

        this.renderer.setStyle(document.body, 'cursor', 'ew-resize');
    }

    private onMouseMove = (event: MouseEvent): void => {
        const minWidth = 320;
        const maxWidth = window.innerWidth / 3;
        let newWidth = this.startWidth - (event.clientX - this.startX);

        if (newWidth <= minWidth) {
            newWidth = minWidth;
        } else if (newWidth >= maxWidth) {
            newWidth = maxWidth;
        }

        this.renderer.setStyle(this.el.nativeElement, 'width', `${newWidth}px`);
        this.offsetChartPanelPosition();
    };

    private onMouseUp = (): void => {
        this.removeMouseEventListeners();
        this.renderer.setStyle(document.body, 'cursor', 'default');

        const currentWidth = this.el.nativeElement.style.width;

        const currentUserSettings = cloneDeep(this.userSettingsStreamService.getCurrentUserSettings());
        currentUserSettings.lastPanelWidth = currentWidth;
        this.userSettingsStreamService.updateCurrentUserSettings(currentUserSettings);
    };

    // offset calculation during on initial load, prepend handlebar and on resize
    private offsetChartPanelPosition(): void {
        const chartsPosition = window.innerWidth - this.el.nativeElement.getBoundingClientRect().left + 5;
        this.chartPanelStreamService.panelOffsetPosition.next(chartsPosition);
    }

    private addMouseEventListeners(): void {
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
        document.addEventListener('touchmove', this.onMouseMove);
        document.addEventListener('touchend', this.onMouseUp);
    }

    private removeMouseEventListeners(): void {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
        document.removeEventListener('touchmove', this.onMouseMove);
        document.removeEventListener('touchend', this.onMouseUp);
    }
}
