import { FullFeatureFilter } from 'src/app/shared/common/feature-filter/full-feature-filter';
import { ExportResponseStatus } from 'src/app/shared/common/polling/export-polling-status.service';
import { GeoLink } from 'src/app/shared/types/geo';

export enum FileType {
    CSV = 'CSV',
    GDB = 'FILEGDB',
    SHAPE = 'SHAPE',
    KML = 'OGCKML',
    PDF = 'PDF',
    DWG = 'DWG',
    DXF = 'DXF',
    FILEGDB_DIC = 'FILEGDB_DIC'
}

export const statusTypes: { id: number; displayName: string }[] = [
    { id: 0, displayName: 'status.unsubmitted' },
    { id: 1, displayName: 'status.error' },
    { id: 2, displayName: 'status.complete' },
    { id: 3, displayName: 'status.in_progress' },
    { id: 4, displayName: 'status.no_data' }
];

export enum FilterType {
    SELECTION = 0,
    VISIBLE = 1,
    CURRENTWORKSPACE = 2,
    OTHERWORKSPACE = 3
}
export const filterOptions: { id: number; displayName: string; description: string }[] = [
    {
        id: FilterType.SELECTION,
        displayName: 'TC.Common.SelectedData',
        description: 'MapViewer_Export_Data_Selected_Description'
    },
    {
        id: FilterType.VISIBLE,
        displayName: 'TC.Common.VisibleData',
        description: 'MapViewer_Export_Data_Visible_Description'
    },
    {
        id: FilterType.CURRENTWORKSPACE,
        displayName: 'TCS.Export.WorkspaceData',
        description: 'MapViewer_Export_Data_Workspace_Description'
    },
    {
        id: FilterType.OTHERWORKSPACE,
        displayName: 'TCS.Export.WorkspaceData',
        description: 'MapViewer_Export_Data_Workspace_Description'
    }
];

export const formatTypes: { id: FileType; displayName: string }[] = [
    { id: FileType.CSV, displayName: 'TC.FileFormats.Csv' },
    { id: FileType.GDB, displayName: 'MapViewer_Export_GDBExport_Name' },
    { id: FileType.SHAPE, displayName: 'TC.Common.Shapefile' },
    { id: FileType.KML, displayName: 'TC.FileFormats.Kml' },
    { id: FileType.PDF, displayName: 'MapViewer_Export_PdfExport_Name' },
    { id: FileType.DWG, displayName: 'TC.FileFormats.Dwg' },
    { id: FileType.DXF, displayName: 'TC.FileFormats.Dxf' },
    { id: FileType.FILEGDB_DIC, displayName: 'MapViewer_Export_GDBExport_New_Name' }
];

export const imageTypes: { id: boolean; displayName: string }[] = [
    { id: false, displayName: 'TC.Common.IncludeAsFiles' },
    { id: true, displayName: 'TC.Common.IncludeAsHyperlinks' }
];

export interface GeoExportJobs {
    links?: GeoLink[];
    total?: number;
    items?: GeoExportJob[];
    eTag?: string;
}
export interface GeoExportJob {
    name?: string;
    emailAddress?: string;
    status?: ExportResponseStatus;
    packageLinks?: string[];
    exportFormat?: FileType;
    exportSRS?: string;
    downloadExpireDate?: string;
    id?: string;
    featuresCount?: number;
    workspaceId?: string;
    fileSizeInBytes?: number;
    createdBy?: string;
    createdUtc?: string;
}

export interface GeoExport {
    name?: string;
    emailAddress?: string;
    workspaceId: string;
    exportFormat?: FileType;
    query?: {
        filter?: any[];
        sortBy?: any[];
        fields?: string[];
        coordinateType?: string;
        includeSchema?: boolean;
        startIndex?: number;
        pageSize?: number;
    };
    exportSRS?: string;
    imagesAsHyperlinks?: boolean;
    markAsExported?: boolean;
    tags?: string[];
    pdfTemplateConnectFileId?: string;
    pdfExportNameFieldName?: string;
    useLatestTemplate?: boolean;
    useCodedChoiceDescription?: boolean;
}

export class Export {
    // DTO properties
    id: string = null;
    name: string = null;
    status = 'Unsubmitted';
    tarLink: string = null;
    packageLinks: string[] = [];
    downloaded = true;
    exportFormat: FileType = null;
    exportSRS: string = null;
    layerName: string = null;
    exportScope: number = FilterType.CURRENTWORKSPACE; // 'mapworkspace' - all workspace data
    exportFilters: { id: number; displayName: string }[] = [];
    downloadExpireDate: string = null;
    featuresDeleted = true;
    emailAddress: string = null;
    workspaceId: string = null;
    featureIds: string[] = [];
    query: FullFeatureFilter = null;
    markAsExported = true;
    imagesAsHyperlinks = true;
    featureCount = 0;
    pdfTemplateConnectFileId: string;
    pdfExportNameFieldName: string;
    exportAllFormAttributes = false;
    useCodedChoiceDescription = false;
    createdBy: string = null;
    createdUtc: string = null;
    tags: string[] = [];

    // Static methods
    static fromDTO(dto: GeoExportJob): Export {
        let exportData = new Export();
        // --------------
        // Create from API response DTO
        if (dto) {
            exportData.id = dto.id;
            exportData.name = dto.name;
            exportData.status = dto.status;
            exportData.packageLinks = dto.packageLinks;
            exportData.exportFormat = dto.exportFormat;
            exportData.exportSRS = dto.exportSRS;
            exportData.downloadExpireDate = dto.downloadExpireDate;
            exportData.emailAddress = dto.emailAddress;
            exportData.query = new FullFeatureFilter();
        }
        return exportData;
    }

    public supportedFormatTypes(
        hasPDFExportLicense: boolean,
        hasCADExportLicense: boolean
    ): { id: FileType; displayName: string }[] {
        return formatTypes.filter(
            type =>
                !(type.id === FileType.PDF && !hasPDFExportLicense) &&
                !(type.id == FileType.FILEGDB_DIC && !hasCADExportLicense)
        );
    }

    // Instance methods

    toDTO(): GeoExport {
        // to API request DTO
        return {
            name: this.name,
            emailAddress: this.emailAddress,
            exportFormat: this.exportFormat,
            query: { filter: this.query.buildFilterRequest() },
            exportSRS: this.exportSRS,
            imagesAsHyperlinks: this.imagesAsHyperlinks,
            markAsExported: this.markAsExported,
            tags: this.tags,
            pdfTemplateConnectFileId: this.pdfTemplateConnectFileId,
            pdfExportNameFieldName: this.pdfExportNameFieldName,
            useLatestTemplate: !this.exportAllFormAttributes,
            useCodedChoiceDescription: this.useCodedChoiceDescription,
            workspaceId: this.workspaceId
        };
    }
}
