<div *ngIf="isAccuracyPanelDisplayed$ | async" class="parent-filter" [style.right.px]="chartPosition">
    <div class="chart-panel">
        <div class="panel-pointer"></div>
        <header class="chart-panel__container">
            <h2>{{ 'MapViewer.Accuracy.Header' | translate }}</h2>
        </header>
        <span
            class="chart-panel__container"
            translate
            [translateParams]="{ filteredFeatureCount: filteredFeatureCount }"
            >MapViewer.Accuracy.FeatureCount.Description
        </span>
        <gsp-chart
            class="chart-panel__container"
            [chartHeight]="250"
            [chartWidth]="400"
            [data]="chartData"
            [options]="chartOptions"
            [plugins]="plugins"
            [updateChartStream]="updateChartStream"
            (chartClick)="onChartClick($event)"
        >
        </gsp-chart>
        <span *ngIf="positionsWithBetterAccuracy?.length" class="chart-panel__container">
            <button
                class="default-link default-link-dark"
                [disabled]="disableBetterAccuracyLink"
                (click)="loadNewFeatures(positionsWithBetterAccuracy)"
                translate
                [translateParams]="{ count: positionsWithBetterAccuracy.length }"
            >
                MapViewer.Accuracy.FeatureCount
            </button>
            {{ 'MapViewer.Accuracy.BetterAccuracy.Description' | translate }}
        </span>
        <span *ngIf="featuresOutsideThreshold?.length" class="chart-panel__container">
            <button
                class="default-link default-link-dark"
                [disabled]="disableOutsideThresholdLink"
                (click)="loadNewFeatures(featuresOutsideThreshold)"
                translate
                [translateParams]="{ count: featuresOutsideThreshold.length }"
            >
                MapViewer.Accuracy.FeatureCount
            </button>
            {{ 'MapViewer.Accuracy.BelowThreshold.Description' | translate }}
        </span>
        <div *ngIf="previousSelectedFeatures" class="previous-selection" (click)="goBack()">
            <div class="previous-selection__icon">
                <i class="i24 icon-arrow-back-white"></i>
            </div>
            <span class="previous-selection__text">{{ 'MapViewer.Accuracy.PreviousSelection.Label' | translate }}</span>
        </div>
    </div>
</div>
