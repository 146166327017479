<!-- Note: directives are in increasing priority order (i.e. latest side-panels appear over earlier ones) -->
<feature-panel
    [@inOutAnimation]="true"
    *ngIf="isPanelOpen(SidePanelName.SELECTED_FEATURES)"
    class="side-panel slide-in-right slide-out-right selected_features_panel"
    resizablePanel
></feature-panel>
<task-detail-panel
    [@inOutAnimation]="true"
    *ngIf="isPanelOpen(SidePanelName.TASK_DETAIL)"
    [taskId]="getPanelParam(SidePanelName.TASK_DETAIL, 'taskId')"
    class="side-panel slide-in-right slide-out-right task_detail_panel"
></task-detail-panel>
<add-existing-layer
    [@inOutAnimation]="true"
    *ngIf="isPanelOpen(SidePanelName.EXISTING_LAYER)"
    class="side-panel slide-in-right slide-out-right existing_layer_panel"
></add-existing-layer>
<add-existing-template
    [@inOutAnimation]="true"
    *ngIf="isPanelOpen(SidePanelName.EXISTING_TEMPLATE)"
    class="side-panel slide-in-right slide-out-right existing_template_panel"
></add-existing-template>
