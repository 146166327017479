import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';
import { Field } from 'src/app/shared/template-services/field';

export interface PreviousSelectedFeatures {
    features: Feature[];
    previousSelection?: PreviousSelectedFeatures;
}

@Injectable({
    providedIn: 'root'
})
export class ChartPanelStreamService {
    private _isAccuracyPanelDisplayedStream = new BehaviorSubject<boolean>(false);
    private _isSummaryPanelDisplayedStream = new BehaviorSubject<boolean>(false);

    private _previousSelectedFeaturesStream = new BehaviorSubject<PreviousSelectedFeatures>(null);

    public isAccuracyPanelDisplayedStream: Observable<boolean> = this._isAccuracyPanelDisplayedStream.asObservable();
    public isFieldSummaryPanelDisplayedStream: Observable<boolean> = this._isSummaryPanelDisplayedStream.asObservable();

    public fieldSummaryPanelSelectedField = new BehaviorSubject<Field>(null);
    public summaryPanelActiveMapStream = new BehaviorSubject<{ [fieldId: string]: boolean }>({});

    public previousSelectedFeaturesStream: Observable<PreviousSelectedFeatures> =
        this._previousSelectedFeaturesStream.asObservable();

    public panelOffsetPosition = new BehaviorSubject<number>(325);

    get isAccuracyPanelDisplayed(): boolean {
        return this._isAccuracyPanelDisplayedStream.getValue();
    }

    set isAccuracyPanelDisplayed(value: boolean) {
        if (value !== undefined) {
            this._isAccuracyPanelDisplayedStream.next(value);
            if (value) {
                // Close summary panel when opening accuracy panel
                this.toggleSummaryPanel();
                this.isSummaryPanelDisplayed = false;
            }
        }
    }

    get isSummaryPanelDisplayed(): boolean {
        return this._isSummaryPanelDisplayedStream.getValue();
    }

    set isSummaryPanelDisplayed(value: boolean) {
        if (value !== undefined) {
            this._isSummaryPanelDisplayedStream.next(value);
        }
    }

    get previousSelectedFeatures(): PreviousSelectedFeatures {
        return this._previousSelectedFeaturesStream.getValue();
    }

    set previousSelectedFeatures(selection: PreviousSelectedFeatures) {
        this._previousSelectedFeaturesStream.next(selection);
    }

    public toggleSummaryPanel(fieldId?: string, autoClose = false) {
        const summaryPanelActiveMap = this.summaryPanelActiveMapStream.getValue();

        if (fieldId === undefined) {
            Object.keys(summaryPanelActiveMap).forEach(key => {
                summaryPanelActiveMap[key] = false;
            });
            return;
        }

        if (!summaryPanelActiveMap[fieldId]) {
            // Set other fields to false since only one field summary can be active at a time
            Object.keys(summaryPanelActiveMap).forEach(key => {
                summaryPanelActiveMap[key] = false;
            });
            // Close accuracy panel when opening summary panel
            if (this.isSummaryPanelDisplayed) {
                this.isAccuracyPanelDisplayed = false;
            }
        }
        summaryPanelActiveMap[fieldId] = autoClose ? false : !summaryPanelActiveMap[fieldId];
        this.summaryPanelActiveMapStream.next(summaryPanelActiveMap);
    }
}
