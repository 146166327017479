import { Component, OnDestroy, OnInit } from '@angular/core';
import _ from 'lodash-es';
import { Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { ACCESS_MODE_KEY, AccessMode, SHARED_MODE_KEY, ShareMode } from 'src/app/core/authentication/share-token';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { Domains, SwappableField } from 'src/app/feature/template-editor/template/domains';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { FeatureFilterStreamService } from 'src/app/shared/common/current-features/feature-filter-stream.service';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { LayersStreams } from 'src/app/shared/common/current-layers/layers-streams.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { AttrValues, FeatureFilter } from 'src/app/shared/common/feature-filter/feature-filter';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { DateUtils } from 'src/app/shared/common/utility/date-utils';
import { NumberUtils } from 'src/app/shared/common/utility/number-utils';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { FieldType, FieldTypeOption } from 'src/app/shared/template-services/field';
import { TemplateService } from 'src/app/shared/template-services/template.service';
import { User } from 'src/app/shared/user/user';
import { UserSettingsStreamService } from 'src/app/shared/user/user-settings-stream.service';

export enum FilterGroup {
    DateModified = 'dateModified',
    ModifiedBy = 'modifiedBy',
    AttrValue = 'attrValue',
    Status = 'status',
    Tags = 'tags',
    OfflineGnssCorrectionStatus = 'offlineGnssCorrectionStatus'
}

@Component({
    templateUrl: './filter-panel.component.html',
    selector: 'filter-panel'
})
export class FilterPanelComponent implements OnInit, OnDestroy {
    public fromDate: Date = null;
    public toDate: Date = null;
    public showClearFilter = false;
    // expose ButtonType enum to template
    public ButtonType = ButtonType;

    private userIds: string[] = [];
    private selectedUsersInfo: string[] = [];
    private selectedCustomTags: string[] = [];
    public tagCheckBoxValues: { [key: string]: boolean } = {};
    public selectedUsers: User[];

    public unassignedUser: boolean;
    public isExported: boolean;
    public isNotExported: boolean;
    public isPostProcessingDone: boolean;
    public isPostProcessingPending: boolean;
    public isPostProcessingFailed: boolean;
    public isPostProcessingUnprocessed: boolean;
    public currentProjectUsers: User[];
    public isPanelSelected = false;

    public currentWorkspace: MapWorkspace;

    public selectedLayer: string;
    public selectedField: string;
    public selectedFieldTypeOption: FieldTypeOption;
    public loading = false;

    // attr values for initial display: default string
    public attrValues: AttrValues = null;
    public attrValuesFrom: string | Date = null;
    public attrValuesTo: string | Date = null;
    public attrValuesMultipleSelect: { [key: string]: boolean } = {};

    public layersAvailable: Layer[] = [];
    public fieldsAvailable: FieldTypeOption[] = [];
    public customTagsAvailable: string[] = [];
    public noCustomTagsCheckbox = false;
    public isAttrValueDisabled = true;
    public isAccordionOpen: { [key: string]: boolean } = {};
    public FilterGroup = FilterGroup;
    public FieldType = FieldType;

    public onAttrValueChangeDebounced = _.debounce(this.onAttrValueChange, 500);

    constructor(
        private featureFilterStream: FeatureFilterStreamService,
        private userSettingsStream: UserSettingsStreamService,
        private projectStream: ProjectStreamService,
        private layersStore: LayersStore,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private layersStreams: LayersStreams,
        private templateService: TemplateService,
        private translationService: TranslationService
    ) {}

    private destroyed = new Subject<void>();

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    async ngOnInit(): Promise<void> {
        this.currentProjectUsers = await this.projectStream.getCurrentProjectUsers();

        combineLatest([
            this.mapWorkspacesStore.currentMapWorkspaceStream,
            this.layersStreams.mapWorkspaceLayersStream,
            this.featureFilterStream.activeFilterStream.pipe(distinctUntilChanged()),
            this.mapWorkspacesStore.workspaceFeatureTagsStream
        ])
            .pipe(
                map(([currentWorkspace, layersAvailable, filters, customTags]) => {
                    this.currentWorkspace = currentWorkspace;
                    this.checkAttrFilterAccordion(layersAvailable);
                    this.layersAvailable = this.layersStreams
                        .sortLayers(layersAvailable)
                        .filter(layer => layer.templateId);
                    this.customTagsAvailable = customTags;
                    return filters;
                }),
                takeUntil(this.destroyed)
            )
            .subscribe(async filters => {
                this.fromDate = filters.fromDate ? (DateUtils.startOfDay(filters.fromDate) as Date) : null;
                this.toDate = filters.toDate ? (DateUtils.startOfDay(filters.toDate) as Date) : null;
                this.userIds = filters.userIds;
                this.selectedUsers = this.currentProjectUsers.filter(
                    user => this.userIds && this.userIds.includes(user.id)
                );

                this.unassignedUser = filters.unassignedUser;
                this.isExported = filters.isExported;
                this.isNotExported = filters.isNotExported;
                this.isPostProcessingDone = filters.isPostProcessingDone;
                this.isPostProcessingPending = filters.isPostProcessingPending;
                this.isPostProcessingFailed = filters.isPostProcessingFailed;
                this.isPostProcessingUnprocessed = filters.isPostProcessingUnprocessed;
                this.selectedUsersInfo = filters.selectedUsersInfo;
                this.selectedLayer = this.layersAvailable.find(layer => layer.id === filters.selectedLayer)
                    ? filters.selectedLayer
                    : null;
                this.selectedField =
                    this.selectedLayer && this.findField(filters.selectedField) ? filters.selectedField : null;
                this.selectedFieldTypeOption = this.selectedLayer ? this.findField(filters.selectedField) : null;
                this.attrValues = this.selectedLayer && this.selectedFieldTypeOption ? filters.attrValues : null;
                this.selectedCustomTags = filters.customTags.filter(tag => this.customTagsAvailable.includes(tag));
                this.tagCheckBoxValues = Object.fromEntries(
                    this.customTagsAvailable.map(tag => [tag, this.selectedCustomTags?.includes(tag)])
                );
                this.noCustomTagsCheckbox = filters.noCustomTags;
                this.fieldsAvailable = await this.initGetTemplateFields(this.findLayer(filters.selectedLayer));

                this.initialLoadOfAttrFilters(filters.attrValues, this.selectedFieldTypeOption);
                this.updateClearFilterButton();
            });

        this.prepareAccordionInitState();
    }

    public applyFilter(): void {
        const filter2 = new FeatureFilter();
        filter2.fromDate = this.fromDate;
        filter2.toDate = this.toDate;
        filter2.userIds = this.userIds;
        filter2.unassignedUser = this.unassignedUser;
        filter2.isExported = this.isExported;
        filter2.isNotExported = this.isNotExported;
        filter2.isPostProcessingDone = this.isPostProcessingDone;
        filter2.isPostProcessingPending = this.isPostProcessingPending;
        filter2.isPostProcessingFailed = this.isPostProcessingFailed;
        filter2.isPostProcessingUnprocessed = this.isPostProcessingUnprocessed;
        filter2.selectedUsersInfo = this.selectedUsersInfo;
        filter2.selectedLayer = this.selectedLayer;
        filter2.selectedField = this.selectedLayer ? this.selectedField : null;
        filter2.selectedFieldTypeOption = this.selectedLayer ? this.findField(this.selectedField) : null;
        filter2.attrValues = this.selectedLayer && this.selectedField ? this.attrValues : null;
        filter2.availableCustomTags = this.customTagsAvailable;
        filter2.customTags = this.customTagsAvailable.filter(tag => this.tagCheckBoxValues[tag]).length
            ? this.customTagsAvailable.filter(tag => this.tagCheckBoxValues[tag])
            : [];
        filter2.noCustomTags = this.noCustomTagsCheckbox;

        const sendFilter = CloneUtils.cloneDeep(filter2);

        // prevent saving the selectedUsersInfo by excluding it from sendFilter
        delete sendFilter.selectedUsersInfo;

        if (!this.currentWorkspace.isPubliclySharedMapWorkspace && !this.currentWorkspace.isFileViewer) {
            const currentMapWorkspaceSettings = this.userSettingsStream.getCurrentMapWorkspaceSettings();
            if (currentMapWorkspaceSettings) {
                currentMapWorkspaceSettings.filters = sendFilter;
                this.userSettingsStream.updateCurrentWorkspaceSettings(currentMapWorkspaceSettings);
            }
        }

        this.featureFilterStream.activeFilter = filter2;
        this.layersStore.refreshNonSpatialCount();
    }

    public applyUserFilter(selectUsers: User[]) {
        this.userIds = selectUsers.map(u => u.id);
        this.selectedUsersInfo = selectUsers.map(u => this.getUserInfo(u));
        this.applyFilter();
    }

    private getUserInfo(user: User): string {
        return user.firstName + ' ' + user.lastName + ' (' + user.email + ')';
    }

    public clearFilter(): void {
        this.fromDate = null;
        this.toDate = null;
        this.userIds = [];
        this.selectedUsersInfo = [];
        this.unassignedUser = null;
        this.isExported = false;
        this.isNotExported = false;
        this.isPostProcessingDone = false;
        this.isPostProcessingPending = false;
        this.isPostProcessingFailed = false;
        this.isPostProcessingUnprocessed = false;
        this.selectedLayer = null;
        this.selectedField = null;
        this.selectedFieldTypeOption = null;
        this.tagCheckBoxValues = {};
        this.noCustomTagsCheckbox = false;
        this.resetAttrValues();

        // reset fields available
        this.fieldsAvailable = [];

        this.applyFilter();
    }

    private updateClearFilterButton(): void {
        let filtersActive = Boolean(
            this.fromDate ||
                this.toDate ||
                (this.userIds && this.userIds.length) ||
                this.unassignedUser ||
                this.isExported ||
                this.isNotExported ||
                this.isPostProcessingDone ||
                this.isPostProcessingPending ||
                this.isPostProcessingFailed ||
                this.isPostProcessingUnprocessed ||
                this.selectedLayer ||
                this.selectedField ||
                this.selectedFieldTypeOption ||
                this.attrValues ||
                this.selectedCustomTags.length ||
                this.noCustomTagsCheckbox
        );

        this.showClearFilter = filtersActive;
    }

    // Added to handle disabling the modified by filter in non full access modes in share workflow
    public getModifiedByFilterVisibility(): boolean {
        if (sessionStorage.getItem(ACCESS_MODE_KEY) === AccessMode.SHARED) {
            if (sessionStorage.getItem(SHARED_MODE_KEY) === ShareMode.PROJECT_USER) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    public toggleAccordion(id: string): void {
        if (!this.isAttrValueDisabled && id === FilterGroup.AttrValue) {
            // special case for attr value accordion
            this.isAccordionOpen[FilterGroup.AttrValue] = !this.isAccordionOpen[FilterGroup.AttrValue];
        } else {
            this.isAccordionOpen[id] = !this.isAccordionOpen[id];
        }
    }

    private disabledAndCloseAttrValue(): void {
        this.isAccordionOpen[FilterGroup.AttrValue] = false;
        this.isAttrValueDisabled = true;
    }

    private prepareAccordionInitState(): void {
        const hasDateModifiedData = Boolean(this.fromDate || this.toDate);
        const hasModifiedByData = Boolean(this.userIds && this.userIds.length);
        const hasAttrValueData = Boolean(this.selectedLayer || this.selectedField || this.attrValues);
        const hasStatusData = this.isExported || this.isNotExported || this.unassignedUser;
        const hasCustomTagsData = Boolean(this.selectedCustomTags.length) || this.noCustomTagsCheckbox;
        const hasOfflineGnssCorrectionStatusData =
            this.isPostProcessingDone ||
            this.isPostProcessingPending ||
            this.isPostProcessingFailed ||
            this.isPostProcessingUnprocessed;
        this.isAccordionOpen = {
            [FilterGroup.DateModified]: hasDateModifiedData,
            [FilterGroup.ModifiedBy]: hasModifiedByData,
            [FilterGroup.AttrValue]: hasAttrValueData,
            [FilterGroup.Status]: hasStatusData,
            [FilterGroup.Tags]: hasCustomTagsData,
            [FilterGroup.OfflineGnssCorrectionStatus]: hasOfflineGnssCorrectionStatusData
        };
    }

    private checkAttrFilterAccordion(layersAvailable?: Layer[]): void {
        if (!layersAvailable?.length) {
            this.disabledAndCloseAttrValue();
            return;
        }

        this.isAttrValueDisabled = false;
    }

    public onSelectLayerChange(layerId: string): void {
        this.selectedField = null;
        this.selectedFieldTypeOption = null;
        this.resetAttrValues();

        this.initGetTemplateFields(this.findLayer(layerId));
        this.applyFilter();
    }

    public onSelectFieldChange(uuid: string): void {
        this.resetAttrValues();
        this.applyFilter();
    }

    public onAttrValueChange(attrValues: AttrValues): void {
        const fieldType = this.findField(this.selectedField)?.filterType;
        const decimalPlaces = this.findField(this.selectedField).hasOwnProperty('decimalPlaces')
            ? this.findField(this.selectedField).decimalPlaces
            : 0;
        this.attrValues = this.organizeAttrValues(fieldType, attrValues, decimalPlaces);
        this.applyFilter();
    }

    private async initGetTemplateFields(findLayer: Layer): Promise<FieldTypeOption[]> {
        if (findLayer?.projectId && findLayer?.templateSeriesId) {
            const templateFields = await this.templateService.getTemplateFields(
                findLayer.projectId,
                findLayer.templateSeriesId
            );

            const disallowedTypes = ['image', 'signature'];
            this.fieldsAvailable = templateFields
                .filter(templateField => !disallowedTypes.some(type => templateField.name.includes(type)))
                .map(templateField => {
                    // initialize all compatible fields per each template field
                    const compatibleFields = this.prepareFieldFilterViewMode(findLayer.geometryType).filter(
                        field => field.name === templateField?.modelType || field.name === templateField.type
                    );

                    const compatibleFieldTypes = _.uniq(compatibleFields.flatMap(field => field.compatibleFields));

                    const decimalPlaceForAutoField = this.selectedFieldTypeOption?.autoField ? 2 : false;

                    const isCodedChoice =
                        templateField.values?.length && templateField.values[0]?.hasOwnProperty('description');

                    const isChoice = templateField.values?.length && templateField.values[0]?.hasOwnProperty('text');

                    const codedChoiceList = isCodedChoice
                        ? templateField.values
                              ?.map((value: { code: string; description: string }, index: number) => {
                                  const { code, description } = value;
                                  return { index, code, description };
                              })
                              .concat({ index: templateField.values?.length, code: 'Other', description: 'Other' })
                        : null;

                    const choiceList = isChoice
                        ? templateField.values
                              ?.map((value: { text: string }, index: number) => ({
                                  index,
                                  text: value.text
                              }))
                              .concat({ index: templateField.values?.length, text: 'Other' })
                        : null;

                    // used as the options for the select dropdown
                    const fieldTypeOption = {
                        uuid: templateField.uuid,
                        label: templateField.displayName,
                        autoField: templateField?.modelType || null,
                        filterType: compatibleFieldTypes[0],
                        name: templateField.name,
                        geometryType: findLayer.geometryType,
                        codedChoiceOrChoiceList: codedChoiceList || choiceList || null,
                        allowMultiSelect: templateField?.allowMultiSelect || false,
                        decimalPlaces: templateField?.scale || decimalPlaceForAutoField || 0
                    } as FieldTypeOption;

                    return fieldTypeOption;
                });
        }
        return this.fieldsAvailable;
    }

    public selectedFieldType(fieldType: string): boolean {
        return this.findField(this.selectedField)?.filterType.includes(fieldType);
    }

    public isCodedChoiceOrChoiceField(): boolean {
        return this.findField(this.selectedField).codedChoiceOrChoiceList?.length > 0;
    }

    public isMultipleChoiceField(): boolean {
        return this.findField(this.selectedField)?.allowMultiSelect || false;
    }

    public getCodedChoiceOrChoiceList(): FieldTypeOption['codedChoiceOrChoiceList'] {
        return this.findField(this.selectedField).codedChoiceOrChoiceList as FieldTypeOption['codedChoiceOrChoiceList'];
    }

    private findLayer(layerId: string): Layer {
        return this.layersAvailable?.find(layer => layer.id === layerId);
    }

    private findField(uuid: string): FieldTypeOption {
        return this.fieldsAvailable?.find(field => field.uuid === uuid);
    }

    private prepareFieldFilterViewMode(geometryType: string): SwappableField[] {
        const fieldFilterViewMode = [FieldType.Text, FieldType.Number, FieldType.Date, FieldType.YesNo];
        const allSwappableFields = fieldFilterViewMode.flatMap(fieldType => {
            return Domains.getSwappableFields(fieldType, geometryType, this.translationService);
        });

        return allSwappableFields;
    }

    private resetAttrValues(): void {
        this.attrValues = null;
        this.attrValuesFrom = null;
        this.attrValuesTo = null;
        this.attrValuesMultipleSelect = {};
    }

    private organizeAttrValues(filterType: string, attrValues: AttrValues, decimalPlaces: number): AttrValues {
        if (this.attrValuesMultipleSelect && Object.keys(this.attrValuesMultipleSelect)?.length) {
            attrValues = Object.keys(this.attrValuesMultipleSelect).filter(key => this.attrValuesMultipleSelect[key]);

            return attrValues;
        }

        switch (filterType) {
            case FieldType.Date:
                const resetToField = !this.attrValuesFrom ? (this.attrValuesTo = null) : false;
                return {
                    from: DateUtils.startOfDay(this.attrValuesFrom) || null,
                    to: DateUtils.endOfDay(this.attrValuesTo) || null
                };
            case FieldType.Number:
                attrValues = NumberUtils.toPrecisedNumber(attrValues, !!decimalPlaces)?.toString();
                return attrValues;
            case FieldType.YesNo:
            case FieldType.Text:
                return attrValues;
            default:
                return null;
        }
    }

    private initialLoadOfAttrFilters(attrValues: AttrValues, selectedFieldTypeOption?: FieldTypeOption): void {
        if (!attrValues || !selectedFieldTypeOption) {
            return;
        }

        switch (typeof attrValues) {
            case 'string':
                this.attrValues = attrValues;
                break;
            case 'object':
                this.handleObjectAttrValues(
                    attrValues as { from: string | Date; to: string | Date } | { [key: string]: boolean },
                    selectedFieldTypeOption
                );
                break;
            case 'boolean':
                this.attrValues = String(attrValues);
                break;
            case 'number':
                this.handleNumberAttrValues(attrValues, selectedFieldTypeOption);
                break;
        }
    }

    private handleObjectAttrValues(
        attrValuesObj: { from: string | Date; to: string | Date } | { [key: string]: boolean },
        selectedFieldTypeOption?: FieldTypeOption
    ): void {
        // check date attrFilter
        if ('from' in attrValuesObj || 'to' in attrValuesObj) {
            attrValuesObj = attrValuesObj as { from: string | Date; to: string | Date };
            this.attrValuesFrom = attrValuesObj?.from;
            this.attrValuesTo = attrValuesObj?.to;
        }

        // multiple select enabled
        if (selectedFieldTypeOption?.allowMultiSelect && Array.isArray(attrValuesObj)) {
            const toObject = (acc: { [x: string]: boolean }, key: string | number) => {
                acc[key] = true;
                return acc;
            };

            this.attrValuesMultipleSelect = attrValuesObj.reduce(toObject, {}) || {};
        }
    }

    private handleNumberAttrValues(attrValues: number, selectedFieldTypeOption?: FieldTypeOption): void {
        this.attrValues = selectedFieldTypeOption
            ? attrValues
            : NumberUtils.toPrecisedNumber(attrValues, !!selectedFieldTypeOption?.decimalPlaces)?.toString();
    }

    public deselectCustomTags(): void {
        this.tagCheckBoxValues = {};
        this.applyFilter();
    }

    public selectCustomTags(): void {
        this.noCustomTagsCheckbox = false;
        this.applyFilter();
    }
}
