import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';
import { GeoLink } from 'src/app/shared/types/geo';

export interface GeoUserSettings {
    links?: GeoLink[];
    total?: number;
    items?: GeoUsersSettings[];
    eTag?: string;
}

export interface GeoUsersSettings {
    name?: string;
    type?: string;
    settingsName?: string;
    value?: any;
    links?: GeoLink[];
    id?: string;
    eTag?: string;
}

export interface GeoUserSettingsRequest {
    type?: string;
    name?: string;
    value?: any;
    settingsName?: string;
}

/**
 * These are the settings related to the login of the current user.
 * There are other settings that are also related to the user i.e.
 * user's project settings and user's workspace settings.
 */
export class UserSettings {
    name = 'me';
    settingsName = 'user-me';
    type = 'user';
    id: string;
    lastLoggedIn: string = null;
    currentProjectPodSpecific: { [key: string]: any } = {};
    currentProjectId: string = null;
    lastProjectId: string = null;
    userId: string = null;
    lastProjectRegion: string = null;
    lastPanelWidth: string = null;

    constructor(userId: string) {
        this.userId = userId;
    }

    static fromDTO(dto: GeoUsersSettings, podLocation: string): UserSettings {
        let settings: UserSettings = null;
        if (dto) {
            settings = new UserSettings(dto.value.id);
            settings.name = dto.name;
            settings.type = dto.type;
            settings.settingsName = dto.settingsName;
            settings.id = dto.value.id;
            settings.lastLoggedIn = dto.value.lastLoggedIn;
            const currentPodProjectIdSettingName = this.getCurrentPodProjectIdSettingName(podLocation);
            settings.currentProjectPodSpecific = {
                currentPodProjectIdSettingName: dto.value[currentPodProjectIdSettingName]
            };
            settings.currentProjectId = dto.value.currentProjectId;
            settings.lastProjectId = dto.value.lastProjectId;
            settings.userId = dto.value.id;
            settings.lastProjectRegion = dto.value.lastProjectRegion;
            settings.lastPanelWidth = dto.value.lastPanelWidth;
        }

        return settings;
    }

    static getCurrentPodProjectIdSettingName(podLocation: string): string {
        return 'current' + podLocation + 'ProjectId';
    }

    static getSettingsName(): string {
        return 'user-me';
    }

    public toDTO(podLocation: string): GeoUserSettingsRequest {
        const settings: any = {};

        settings.id = this.userId;
        settings.lastPanelWidth = this.lastPanelWidth || null;

        if (!GeneralUtils.isNullUndefinedOrNaN(this.lastLoggedIn)) {
            settings.lastLoggedIn = this.lastLoggedIn;
        }

        if (!GeneralUtils.isNullUndefinedOrNaN(this.currentProjectId)) {
            settings.currentProjectId = this.currentProjectId;
            settings.lastProjectId = this.lastProjectId;
            const currentPodProjectIdSettingName = UserSettings.getCurrentPodProjectIdSettingName(podLocation);
            settings[currentPodProjectIdSettingName] = this.currentProjectPodSpecific[currentPodProjectIdSettingName];
            settings.lastProjectRegion = podLocation || this.lastProjectRegion;
        }

        return {
            name: this.name,
            type: this.type,
            settingsName: this.settingsName ? this.settingsName : UserSettings.getSettingsName(),
            value: settings
        };
    }
}
