import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { AppMaterialModule } from 'src/app/app-material.module';
import { TranslationModule } from 'src/app/core/translation/translation.module';
import { ImportModule } from 'src/app/feature/import/import.module';
import { EditLayerComponent } from 'src/app/feature/map-viewer/edit-layer/edit-layer.component';
import { EditOfflineBasemapComponent } from 'src/app/feature/map-viewer/edit-offline-basemap/edit-offline-basemap.component';
import { MainMenuComponent } from 'src/app/feature/map-viewer/main-menu/main-menu.component';
import { MapContainerComponent } from 'src/app/feature/map-viewer/map-container/map-container.component';
import { MapActionsComponent } from 'src/app/feature/map-viewer/map-container/map-draw-actions/map-draw-actions.component';
import { MapLayoutComponent } from 'src/app/feature/map-viewer/map-layout.component';
import { FilterPanelComponent } from 'src/app/feature/map-viewer/map-menus/filter-panel/filter-panel.component';
import { AddMenuComponent } from 'src/app/feature/map-viewer/map-menus/map-panel/add-menu/add-menu.component';
import { LayerListComponent } from 'src/app/feature/map-viewer/map-menus/map-panel/layer-list/layer-list.component';
import { RemoveLayerComponent } from 'src/app/feature/map-viewer/map-menus/map-panel/layer-list/remove-layer.component';
import { MapCacheListComponent } from 'src/app/feature/map-viewer/map-menus/map-panel/map-cache-list/map-cache-list.component';
import { MapProviderListComponent } from 'src/app/feature/map-viewer/map-menus/map-panel/map-provider/map-provider.component';
import { MenuTabsComponent } from 'src/app/feature/map-viewer/map-menus/menu-tabs.component';
import { MenuComponent } from 'src/app/feature/map-viewer/map-menus/menu.component';
import { TaskPanelComponent } from 'src/app/feature/map-viewer/map-menus/task-panel/task-panel.component';
import { MapSelectionComponent } from 'src/app/feature/map-viewer/map-selection/map-selection.component';
import { MapViewerRoutingModule } from 'src/app/feature/map-viewer/map-viewer-routing.module';
import { AddExistingLayerComponent } from 'src/app/feature/map-viewer/side-panel/add-existing-layer/add-existing-layer.component';
import { AddExistingTemplateComponent } from 'src/app/feature/map-viewer/side-panel/add-existing-template/add-existing-template.component';
import { AutoFieldComponent } from 'src/app/feature/map-viewer/side-panel/feature-panel/feature-fields/auto-field/auto-field.component';
import { ChoiceFieldComponent } from 'src/app/feature/map-viewer/side-panel/feature-panel/feature-fields/choice-field/choice-field.component';
import { CodedChoiceFieldComponent } from 'src/app/feature/map-viewer/side-panel/feature-panel/feature-fields/coded-choice-field/coded-choice-field.component';
import { DateFieldComponent } from 'src/app/feature/map-viewer/side-panel/feature-panel/feature-fields/date-field/date-field.component';
import { ImageFieldComponent } from 'src/app/feature/map-viewer/side-panel/feature-panel/feature-fields/image-field/image-field.component';
import { NumberFieldComponent } from 'src/app/feature/map-viewer/side-panel/feature-panel/feature-fields/number-field/number-field.component';
import { TextFieldComponent } from 'src/app/feature/map-viewer/side-panel/feature-panel/feature-fields/text-field/text-field.component';
import { YesNoFieldComponent } from 'src/app/feature/map-viewer/side-panel/feature-panel/feature-fields/yes-no-field/yes-no-field.component';
import { FeaturePanelComponent } from 'src/app/feature/map-viewer/side-panel/feature-panel/feature-panel.component';
import { FeatureWithNoTemplateComponent } from 'src/app/feature/map-viewer/side-panel/feature-panel/feature-with-no-template.component';
import { FeatureWithTemplateComponent } from 'src/app/feature/map-viewer/side-panel/feature-panel/feature-with-template.component';
import { MiniMapComponent } from 'src/app/feature/map-viewer/side-panel/feature-panel/mini-map/mini-map.component';
import { SidePanelComponent } from 'src/app/feature/map-viewer/side-panel/side-panel.component';
import { TaskDetailPanelComponent } from 'src/app/feature/map-viewer/side-panel/task-detail-panel/task-detail-panel.component';
import { MapMainMenuComponent } from 'src/app/feature/map-viewer/toolbar/map-main-menu/map-main-menu.component';
import { CollaborationToolsComponent } from 'src/app/feature/map-viewer/toolbar/mapToolbar/collaborationTools/collaboration-tools.component';
import { MapToolbarComponent } from 'src/app/feature/map-viewer/toolbar/mapToolbar/map-toolbar.component';
import { RefreshToolsComponent } from 'src/app/feature/map-viewer/toolbar/mapToolbar/refreshTools/refresh-tools.component';
import { SelectionToolComponent } from 'src/app/feature/map-viewer/toolbar/mapToolbar/selectionTools/selection-tool.component';
import { TopToolbarComponent } from 'src/app/feature/map-viewer/toolbar/mapToolbar/top-toolbar.component';
import { ZoomButtonsComponent } from 'src/app/feature/map-viewer/toolbar/mapToolbar/zoomTools/zoom-buttons.component';
import { WorkspaceDetailComponent } from 'src/app/feature/map-viewer/toolbar/workspace-detail/workspace-detail.component';
import { PublicSharedViewerComponent } from 'src/app/feature/shared-viewer/public-shared-viewer.component';
import { WorkspaceModule } from 'src/app/feature/workspace/workspace.module';
import { CommonModule as AppCommonModule } from 'src/app/shared/common/common.module';

import { ImageCropperModule } from 'ngx-image-cropper';
import { UserFilterComponent } from 'src/app/shared/common/components/user-filter/user-filter.component';
import { PostProcessingModule } from '../post-processing/post-processing.module';
import { RtcModule } from '../rtc/rtc.module';
import { TemplateEditorModule } from '../template-editor/template-editor.module';
import { AccuracyPanelComponent } from './accuracy-panel/accuracy-panel.component';
import { DiscardDraftChangesComponent } from './discard-draft-changes/discard-draft-changes.component';
import { PositioningComponent } from './main-menu/positioning.component';
import { FileViewerUploadStatusComponent } from './map-menus/map-panel/upload-status/fileviewer/fileviewer-upload-status.component';
import { MapViewerUploadStatusComponent } from './map-menus/map-panel/upload-status/mapviewer/upload-status.component';
import { FeatureListComponent } from './side-panel/feature-panel/feature-list/feature-list.component';
import { FieldSummaryPanelComponent } from './side-panel/field-summary-panel/field-summary-panel.component';
import { ResizablePanelDirective } from './side-panel/resizable-panel.directive';

@NgModule({
    declarations: [
        MapContainerComponent,
        MapActionsComponent,
        MapSelectionComponent,
        SidePanelComponent,
        TaskDetailPanelComponent,
        FeaturePanelComponent,
        MiniMapComponent,
        FeatureListComponent,
        AddExistingLayerComponent,
        AutoFieldComponent,
        AddExistingTemplateComponent,
        ImageFieldComponent,
        ChoiceFieldComponent,
        CodedChoiceFieldComponent,
        YesNoFieldComponent,
        TextFieldComponent,
        DateFieldComponent,
        NumberFieldComponent,
        FeatureWithNoTemplateComponent,
        FeatureWithTemplateComponent,
        MapLayoutComponent,
        CollaborationToolsComponent,
        RefreshToolsComponent,
        SelectionToolComponent,
        ZoomButtonsComponent,
        MapToolbarComponent,
        TopToolbarComponent,
        AddMenuComponent,
        LayerListComponent,
        RemoveLayerComponent,
        MapCacheListComponent,
        MapProviderListComponent,
        TaskPanelComponent,
        MenuComponent,
        MenuTabsComponent,
        FilterPanelComponent,
        MapMainMenuComponent,
        MainMenuComponent,
        EditLayerComponent,
        EditOfflineBasemapComponent,
        PublicSharedViewerComponent,
        WorkspaceDetailComponent,
        MapViewerUploadStatusComponent,
        FileViewerUploadStatusComponent,
        PositioningComponent,
        AccuracyPanelComponent,
        DiscardDraftChangesComponent,
        UserFilterComponent,
        FieldSummaryPanelComponent,
        ResizablePanelDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslationModule,
        AppCommonModule,
        GalleryModule,
        LightboxModule,
        MatAutocompleteModule,
        MatIconModule,
        MatChipsModule,
        MapViewerRoutingModule,
        ImportModule,
        WorkspaceModule,
        AppMaterialModule,
        RtcModule,
        PostProcessingModule,
        TemplateEditorModule,
        ImageCropperModule
    ],
    exports: [MapContainerComponent]
})
export class MapViewerModule {}
