<div
    class="parent-filter"
    *ngIf="isSummaryPanelDisplayed$ | async"
    [style.top.px]="335"
    [style.right.px]="chartPosition"
>
    <div class="chart-panel summary-panel">
        <header class="chart-panel__container">
            <h2 title="{{ field.displayName }}">{{ field.displayName }}</h2>
        </header>
        <span
            class="chart-panel__container"
            translate
            [translateParams]="{
                fieldType: translatedFieldType,
                selectedFeaturesCount: selectedFeaturesCount
            }"
            >MapViewer.SummaryPanel.Description
        </span>
        <gsp-chart
            *ngIf="chartData.labels"
            class="chart-panel__container"
            [chartHeight]="264"
            [chartWidth]="264"
            [chartType]="chartType"
            [data]="chartData"
            [labels]="chartData.labels"
            [options]="chartOptions"
            [plugins]="plugins"
            [updateChartStream]="updateChartStream"
            (chartClick)="onChartClick($event)"
        >
        </gsp-chart>

        <div *ngIf="previousSelectedFeatures" class="previous-selection" (click)="goBack()">
            <div class="previous-selection__icon">
                <i class="i24 icon-arrow-back-white"></i>
            </div>
            <span class="previous-selection__text">{{ 'MapViewer.Accuracy.PreviousSelection.Label' | translate }}</span>
        </div>
    </div>
</div>
