import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { LayerService } from 'src/app/shared/map-data-services/layer/layer.service';
import { TemplateLite } from 'src/app/shared/map-data-services/layer/template-lite';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { Style } from 'src/app/shared/map-data-services/styles/style';
import { CurrentEditTemplateStream } from 'src/app/shared/template-services/current-edit-template-stream.service';
import { Field } from 'src/app/shared/template-services/field';
import { Template } from 'src/app/shared/template-services/template';
import { TemplateService } from 'src/app/shared/template-services/template.service';

import { StylesStore } from '../current-layers/styles-store.service';
import { ProjectStreamService } from '../current-project/project-stream.service';
import { CloneUtils } from '../utility/clone-utils';

@Injectable({
    providedIn: 'root'
})
export class TemplatesStoreService {
    private templatesCache: { [templateId: string]: Template } = {};
    private templatesCacheBySeriesIdByVersion: { [seriesId: string]: { [version: number]: Template } } = {};
    private workspaceTemplatesCache: { [templateId: string]: Template } = {};
    private currentMapWorkspaceTemplates = new BehaviorSubject<{ [templateId: string]: Template }>({});

    private currentProjectId: string = null;

    constructor(
        private templateService: TemplateService,
        private projectStream: ProjectStreamService,
        private stylesStore: StylesStore,
        private translate: TranslationService,
        private layerService: LayerService,
        private currentEditTemplateStream: CurrentEditTemplateStream
    ) {
        projectStream.currentProjectStream.subscribe(project => {
            this.currentProjectId = project ? project.id : null;
        });
    }

    public setCurrentEditedTemplate(template: Template): void {
        this.currentEditTemplateStream.next(template);
    }

    public getCurrentMapWorkspaceTemplates(): { [templateId: string]: Template } {
        return this.currentMapWorkspaceTemplates.getValue();
    }

    public loadWorkspaceTemplates(mapWorkspace: MapWorkspace): void {
        if (mapWorkspace && mapWorkspace.id) {
            this.workspaceTemplatesCache = {};
            this.templateService.getTemplates(mapWorkspace.projectId, mapWorkspace.id).then(templates => {
                templates.map(template => {
                    this.templatesCache[template.id] = template;
                    this.addOrUpdateTemplatesCacheBySeriesId(template);
                    this.workspaceTemplatesCache[template.id] = this.templatesCache[template.id];
                });
                this.currentMapWorkspaceTemplates.next(this.workspaceTemplatesCache);
            });
        }
    }

    // Gets a template but also populates it with the publication status info specific to the specified layer
    public getTemplateByLayer(layer: Layer, publishStatusRequired = true, doNotFetchAgain = false): Promise<Template> {
        return this.getTemplateByTemplateAndLayerIds(
            layer.templateId,
            layer.id,
            publishStatusRequired,
            doNotFetchAgain
        );
    }

    // Gets a template but also populates it with the publication status info specific to the specified layer
    public getTemplateByTemplateAndLayerIds(
        templateId: string,
        layerId: string,
        publishStatusRequired = true,
        doNotFetchAgain = false
    ): Promise<Template> {
        return this.getTemplateById(templateId).then(template =>
            doNotFetchAgain
                ? Promise.resolve(template)
                : publishStatusRequired
                ? Promise.all([
                      this.determineLayersbyTemplateId(this.currentProjectId, template.id),
                      this.getLastPublishedTemplateVersionId(template)
                  ]).then(() => template)
                : Promise.resolve(template)
        );
    }

    public addOrUpdateTemplate(template: Template): void {
        if (!this.templatesCache[template.id]) {
            this.templatesCache[template.id] = template;
        } else {
            this.templatesCache[template.id] = template;
        }
    }

    public getTemplateById(templateId: string): Promise<Template> {
        let template = this.templatesCache[templateId];
        return template
            ? Promise.resolve(template)
            : this.templateService
                  .getTemplateById(this.projectStream.getCurrentProject().id, templateId)
                  .then(newTemplate => {
                      template = newTemplate;
                      this.templatesCache[template.id] = template;
                      this.addOrUpdateTemplatesCacheBySeriesId(template);
                      return template;
                  });
    }

    public getTemplateBySeriesId(seriesId: string, version?: number): Promise<Template> {
        let latestVersion: number = version;
        let template: Template;
        if (!latestVersion && this.templatesCacheBySeriesIdByVersion[seriesId]) {
            let templatesBySeriesId = _.values(this.templatesCacheBySeriesIdByVersion[seriesId]);
            template = _.maxBy(
                templatesBySeriesId,
                (templateToBeIdentified: Template) => templateToBeIdentified.version
            );
            latestVersion = template.version;
        } else {
            template =
                this.templatesCacheBySeriesIdByVersion[seriesId] &&
                this.templatesCacheBySeriesIdByVersion[seriesId][latestVersion];
        }
        return template
            ? Promise.resolve(template)
            : this.templateService
                  .getTemplateBySeriesId(this.projectStream.getCurrentProject().id, seriesId)
                  .then(templates => {
                      if (templates) {
                          templates.forEach(newTemplate => {
                              this.templatesCache[newTemplate.id] = newTemplate;
                              this.addOrUpdateTemplatesCacheBySeriesId(newTemplate);
                          });
                      }
                      latestVersion = latestVersion ? latestVersion - 1 : 0;
                      return templates[latestVersion];
                  });
    }

    private addOrUpdateTemplatesCacheBySeriesId(template: Template): void {
        let existingTemplate =
            this.templatesCacheBySeriesIdByVersion[template.seriesId] &&
            this.templatesCacheBySeriesIdByVersion[template.seriesId][template.version];

        if (!existingTemplate || template.version >= existingTemplate.version) {
            if (!this.templatesCacheBySeriesIdByVersion[template.seriesId]) {
                this.templatesCacheBySeriesIdByVersion[template.seriesId] = {};
            }
            this.templatesCacheBySeriesIdByVersion[template.seriesId][template.version] = template;
        }
    }

    public async createTemplate(template: Template): Promise<Template> {
        const createdTemplate = await this.templateService.createTemplate(this.currentProjectId, template.toDTO());
        createdTemplate.attachedToWorkspaces = [];
        createdTemplate.attachedToLayers = [];
        createdTemplate.layerAttachedWorkspaces = [];
        this.workspaceTemplatesCache[createdTemplate.id] = this.templatesCache[createdTemplate.id] = createdTemplate;
        return createdTemplate;
    }

    async getLastPublishedTemplateVersionId(template: Template): Promise<Template> {
        const templates = await this.templateService.getAllPublishedTemplates(this.currentProjectId, template.seriesId);
        if (templates.length) {
            template.lastPublishedVersion = templates[templates.length - 1].version;
        }
        return template;
    }

    // Get all the fields deltas in the template within a series
    public getTemplateDeltas(
        template: Template,
        lastPublishedVersion: number
    ): Promise<{ archived: Field[]; unPublished: Field[] }> {
        return Promise.resolve(
            template.seriesId
                ? this.templateService.getTemplateDeltas(template.seriesId, this.currentProjectId, lastPublishedVersion)
                : { archived: [], unPublished: [] }
        );
    }

    public async createOrUpdateStyle(template: TemplateLite): Promise<Style> {
        const styles = await Promise.all([this.stylesStore.loadProjectStyles(this.currentProjectId)]);
        let matchingStyle = _.find(styles[0], style => style.name === 'style' + template.geometryColorHexRGB);
        if (matchingStyle) {
            return this.stylesStore.updateStyle(this.currentProjectId, matchingStyle.id, matchingStyle);
        } else {
            let newStyle = new Style();
            newStyle.setColor(template.geometryColorHexRGB);
            newStyle.setName('style' + template.geometryColorHexRGB);
            return this.stylesStore.createStyle(this.currentProjectId, newStyle);
        }
    }

    public async createDuplicateTemplateFromLayer(layer: Layer): Promise<Template> {
        const original = await this.getTemplateByLayer(layer, true);
        return this.duplicateTemplate(original);
    }

    public async createDuplicateTemplateFromId(templateId: string): Promise<Template> {
        const original = await this.getTemplateById(templateId);
        return this.duplicateTemplate(original);
    }

    private duplicateTemplate(original: Template): Promise<Template> {
        const copy = CloneUtils.cloneDeep(original);
        let pattern = copy.name + ' ' + this.translate.instant('TC.Common.Copy');
        if (pattern.length >= 100) {
            const digits = pattern.length - 100 + 1;
            copy.name = copy.name.slice(0, -digits);
            pattern = copy.name + ' ' + this.translate.instant('TC.Common.Copy');
        }
        copy.name = pattern;
        copy.id = '';
        copy.hasActiveTasks = false;
        if (copy.lockedSchema) {
            copy.lockedSchema = false;
            copy.fields = this.unlockFields(copy.fields);
        }
        return this.createTemplate(copy);
    }

    public async determineLayersbyTemplateId(projectId: string, templateId: string): Promise<Template> {
        let template = new Template();
        template.attachedToLayers = [];
        const layers = await this.layerService.getLayersByTemplateId(projectId, templateId);
        template.attachedToLayers = layers;
        template.layersWithWorkspaces = layers.filter(
            layer => layer.workspaces.filter(workspace => !workspace.isDeleted).length > 0
        );
        template.layerAttachedWorkspaces = [];
        layers.forEach(layer => {
            template.layerAttachedWorkspaces = template.layerAttachedWorkspaces.concat(layer.workspaces);
        });
        template.layerAttachedWorkspaces = _.uniqBy(template.layerAttachedWorkspaces, 'id');
        template.attachedToWorkspaces = template.layerAttachedWorkspaces;
        return template;
    }

    private unlockFields(fields: Field[]): Field[] {
        return fields.map(field => {
            field.locked = false;
            if (field.fields) {
                field.fields = this.unlockFields(field.fields);
            }
            return field;
        });
    }
}
