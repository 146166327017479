import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { FeatureMapLayersService } from 'src/app/feature/map-viewer/map-container/feature-map-layers/feature-map-layers.service';
import { MapService } from 'src/app/feature/map-viewer/map.service';
import { ActiveFeatureStreamsService } from 'src/app/shared/common/current-features/active-feature-streams.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { GeometryUtils } from 'src/app/shared/common/utility/geometry-utils';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';
import { FeatureService } from 'src/app/shared/map-data-services/feature/feature.service';
import { UserSettingsStreamService } from 'src/app/shared/user/user-settings-stream.service';

import { BaseMapProvider } from '../../../base-maps/base-map-provider';
import { FeatureMapLayer } from '../../../map-container/feature-map-layers/feature-map-layer';

export const GLOBAL_COORDINATESYSTEM_ID = '|Trimble:1034|||';
@Component({
    selector: 'mini-map',
    templateUrl: './mini-map.component.html'
})
export class MiniMapComponent implements OnInit, OnDestroy {
    @Input()
    baseMapProviderName: string;

    @Input()
    baseMapModeName: string;

    @Input()
    mapId: string;

    @ViewChild('miniMap', { static: true })
    mapElement: ElementRef;

    private map: L.Map = null;
    private miniMapLayer: FeatureMapLayer = null;
    private miniMapMaxZoom = 16;
    private attributionControl: L.Control.Attribution;

    private readonly destroyed = new Subject<void>();
    baseMapProviders: BaseMapProvider[];

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    constructor(
        private mapService: MapService,
        private activeFeatureStreams: ActiveFeatureStreamsService,
        private featureMapLayersService: FeatureMapLayersService,
        private featureService: FeatureService,
        private mapworkspacesStore: MapWorkspacesStoreService,
        private userSettingsStreamService: UserSettingsStreamService,
        private projectStream: ProjectStreamService,
        private translate: TranslationService
    ) {}

    ngOnInit(): void {
        const baseMapProviders = this.mapService.intializeBaseMapProvider();
        this.baseMapProviders = baseMapProviders;
        const map = this.mapService.intializeMap(
            this.mapElement.nativeElement,
            this.baseMapProviderName,
            this.baseMapModeName,
            {
                attributionControl: false,
                maxZoom: this.miniMapMaxZoom // Trimble map max zoom
            }
        );
        this.map = map;

        this.activeFeatureStreams.activeFeatureStream.pipe(takeUntil(this.destroyed)).subscribe(currentFeature => {
            if (!!currentFeature) {
                this.onLoadMiniMap(currentFeature);
            }
        });

        this.map.on('mouseover', () => {
            this.attributionControl = L.control.attribution({ prefix: false });
            this.map.addControl(this.attributionControl);
        });

        this.map.on('mouseout', () => {
            this.map.removeControl(this.attributionControl);
        });

        this.map.on('resize', () => {
            this.map.invalidateSize();
        });
    }

    onLoadMiniMap(currentFeature: Feature): Feature {
        // reset miniMapLayer if there is a current selected feature
        if (this.miniMapLayer) {
            this.map.removeLayer(this.miniMapLayer);
        }

        if (!currentFeature.geometry?.coordinates) {
            const workspaceSettings = this.userSettingsStreamService?.getCurrentMapWorkspaceSettings();

            if (workspaceSettings) {
                let { lat, lng } = workspaceSettings.center;

                // set a new view that follows current user's view if no coordinates
                this.map.setView(L.latLng(lat, lng), this.miniMapMaxZoom);
            }
        } else {
            this.getAttributionText(currentFeature).then(attr => {
                currentFeature.attributionText = attr;
            });

            // set a new view to new coordinate defined
            this.miniMapLayer = this.featureMapLayersService.createFeatureMapLayer(currentFeature);
            this.miniMapLayer.addTo(this.map);
            this.miniMapLayer.getAttribution = () => currentFeature.attributionText;
            this.map.fitBounds(GeometryUtils.getBounds(currentFeature.geometry), {
                maxZoom: this.miniMapMaxZoom
            });
        }

        // Allows resizing of the map when the container / parent size changes
        const resizeObserver = new ResizeObserver(() => {
            this.map.invalidateSize();
        });
        resizeObserver.observe(this.mapElement.nativeElement);

        return currentFeature;
    }

    private async getAttributionText(currentFeature: Feature): Promise<string> {
        try {
            if (currentFeature.geometry.type === 'Point') {
                const currentWorkspace = this.mapworkspacesStore.getCurrentMapWorkspace();
                await this.featureService.getLocalGeometryAndCRS(this.projectStream.currentProject.id, currentFeature);

                if (currentFeature.localGeometry) {
                    const coordinates = await this.featureService.transformCoordinatesFromLocalCrsToWorkspaceCrs(
                        currentWorkspace.getLocalCoordinateSystemId(),
                        currentWorkspace.coordinateSystem.cscmResourceId,
                        currentFeature.localGeometry.coordinates
                    );

                    if (coordinates) {
                        const text = `${coordinates.lat.toFixed(6)},${coordinates.lng.toFixed(6)}`;
                        if (coordinates.alt) {
                            const altName = this.translate.instant(
                                currentWorkspace.coordinateSystem.geoidComponentId
                                    ? 'ModelDetails.Elevation.Text'
                                    : 'TCS.ModelDefinitionEditor.SymbolFields.Height'
                            );
                            return text + `. ${altName}: ${coordinates.alt.toFixed(6)}`;
                        }
                        return text;
                    }
                }
            }
            return '';
        } catch (e) {
            return '';
        }
    }
}
