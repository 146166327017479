import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, throttleTime } from 'rxjs/operators';

import { SearchMode } from 'src/app/feature/map-viewer/side-panel/add-existing-template/add-existing-template.component';
import { TemplateService } from '../../template-services/template.service';
import { LayersStore } from '../current-layers/layers-store.service';
import { MapWorkspaceStreamsService } from '../current-map-workspaces/map-workspace-streams.service';
import { ProjectStreamService } from '../current-project/project-stream.service';

export enum GeometryType {
    Point = 0,
    Line = 1,
    Area = 2
}

export interface MinifiedTemplate {
    name: string;
    geometryType: string;
    geometryColorHexRGB: string;
}

export interface MinifiedWorkspace {
    id: string;
    name: string;
    description: string;
}

export interface TemplateWorkspaceMapping {
    id: string;
    seriesId: string;
    version: number;
    template: MinifiedTemplate;
    workspaces: MinifiedWorkspace[];
}

@Injectable({
    providedIn: 'root'
})
export class ProjectTemplateStreams {
    public projectTemplateLoadingStream = new BehaviorSubject<boolean>(false);

    private projectTemplatesStream = new BehaviorSubject<TemplateWorkspaceMapping[]>([]);

    private projectTemplatesFilteredStream = new BehaviorSubject<TemplateWorkspaceMapping[]>([]);

    // The list of filtered templates for template library screen
    public templateLibraryStream: Observable<TemplateWorkspaceMapping[]>;

    // Stream to indicate if all pages have been loaded in template library infinite scroll
    public isTemplateLibraryPaginationEndStream = new BehaviorSubject<boolean>(false);

    // Search string in template library search bar
    private searchTemplateLibraryStream = new BehaviorSubject<string>(null);

    constructor(
        private projectStream: ProjectStreamService,
        private mapWorkspaceStreams: MapWorkspaceStreamsService,
        private templateService: TemplateService,
        private layerStore: LayersStore
    ) {
        combineLatest([
            this.projectStream.currentProjectStream,
            this.layerStore.changedMapWorkspaceLayerStream, // to refresh the stream on template creation/updation
            this.mapWorkspaceStreams.projectMapWorkspacesStream // to refresh the stream on workspace rename/archive/unarchive
        ])
            .pipe(distinctUntilChanged(_.isEqual), throttleTime(2000))
            .subscribe(([project, changedMapWorkspaceLayer, workspaces]) => {
                if (project && project.id) {
                    this.projectTemplateLoadingStream.next(true);
                    this.projectTemplatesStream.next([]);
                    this.templateService
                        .getTemplatesWorkspacesMappingListPaged(project.id)
                        .then((templateMappings: TemplateWorkspaceMapping[]) => {
                            // grouping the templatesMapping by seriesId with unique workspace mappings
                            let uniqueTemplatesGroupingObj: { [key: string]: TemplateWorkspaceMapping } = {};
                            templateMappings.forEach(templateMapping => {
                                if (uniqueTemplatesGroupingObj[templateMapping.seriesId]) {
                                    uniqueTemplatesGroupingObj[templateMapping.seriesId].workspaces = _.uniqBy(
                                        [
                                            ...uniqueTemplatesGroupingObj[templateMapping.seriesId].workspaces,
                                            ...templateMapping.workspaces
                                        ],
                                        'id'
                                    );
                                } else {
                                    uniqueTemplatesGroupingObj[templateMapping.seriesId] = templateMapping;
                                }
                            });
                            templateMappings = Object.values(uniqueTemplatesGroupingObj);
                            this.projectTemplatesStream.next(templateMappings);
                            this.projectTemplateLoadingStream.next(false);
                        });
                }
            });

        this.searchTemplateLibraryStream.pipe(distinctUntilChanged()).subscribe(() => {
            this.projectTemplatesStream.next(this.projectTemplatesStream.getValue());
        });

        this.templateLibraryStream = this.projectTemplatesFilteredStream.pipe(
            map(res => res.sort(this.sortTemplateByGeomTypeAndName)),
            distinctUntilChanged()
        );
    }

    public async searchTemplateInLibrary(searchText: string, searchMode: SearchMode, page: number): Promise<void> {
        this.searchTemplateLibraryStream.next(searchText);

        if (!searchMode) {
            // Filter by both template name and workspace name
            this.getPagedTemplates(page, searchText, searchText);
        } else if (searchMode === SearchMode.TEMPLATE_NAME) {
            this.getPagedTemplates(page, searchText, null);
        } else if (searchMode === SearchMode.WORKSPACE_NAME) {
            this.getPagedTemplates(page, null, searchText);
        }
    }

    private getPagedTemplates(page: number, templateName?: string, workspaceName?: string): void {
        const projectId = this.projectStream.currentProjectStream.getValue().id;
        const pageSize = 100;
        const startIndex = (page - 1) * pageSize;

        this.templateService
            .getTemplatesWorkspacesMappingListPaged(projectId, startIndex, pageSize, templateName, workspaceName)
            .then(geoTemplates => {
                if (page > 1) {
                    // Append the new template page to the existing templates list (for infinite scroll)
                    const updatedTemplatesList = [
                        ...this.projectTemplatesFilteredStream.getValue(),
                        ...(geoTemplates as TemplateWorkspaceMapping[])
                    ];
                    this.projectTemplatesFilteredStream.next(updatedTemplatesList);
                } else {
                    this.projectTemplatesFilteredStream.next(geoTemplates as TemplateWorkspaceMapping[]);
                }

                // If the new templates list size is less than pageSize, it means all pages have been loaded
                if (geoTemplates.length < 100) {
                    this.isTemplateLibraryPaginationEndStream.next(true);
                }
            })
            .catch(error => error.status === 416 && this.isTemplateLibraryPaginationEndStream.next(true))
            .finally(() => this.projectTemplateLoadingStream.next(false));
    }

    private sortTemplateByGeomTypeAndName(a: TemplateWorkspaceMapping, b: TemplateWorkspaceMapping): number {
        // using solution to sort by multiple fields from here:
        // https://stackoverflow.com/questions/6129952/javascript-sort-array-by-two-fields

        const aGeomType = GeometryType[a.template.geometryType as any];
        const bGeomType = GeometryType[b.template.geometryType as any];
        const aName = a.template.name.toLowerCase();
        const bName = b.template.name.toLowerCase();
        if (aGeomType === bGeomType) {
            return aName < bName ? -1 : aName > bName ? 1 : 0;
        } else {
            return aGeomType < bGeomType ? -1 : 1;
        }
    }
}
