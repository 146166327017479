import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LatLng } from 'leaflet';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { GspLoggerService } from 'src/app/log-handler.service';
import { NotificationType } from 'src/app/shared/common/components/gsp-notification/gsp-notification.component';
import {
    GspWizardService,
    OnBackward,
    OnForward
} from 'src/app/shared/common/components/gsp-wizard/gsp-wizard.service';
import { CurrentEntitlementStreamService } from 'src/app/shared/common/current-entitlement/current-entitlement-stream.service';
import { EntitlementSKUs } from 'src/app/shared/common/current-entitlement/entitlement';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { LayersStreams } from 'src/app/shared/common/current-layers/layers-streams.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { LoaderStreamService } from 'src/app/shared/common/loader/loader-stream.service';
import { ModalSize } from 'src/app/shared/common/modal-sizes';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import {
    CANADA_DATUM_RESOURCE_URL,
    CoordinateSystemComponent,
    CoordinateSystemComponentType,
    LATLONG_SYSTEM_COMPONENT_ID,
    MapWorkspace,
    US_STATE_PLANE_COMPONENT_ID,
    coordinateSystemComponentUnits,
    usCountyUnitsDict
} from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import {
    CSComponentScopes,
    MapWorkspaceService
} from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.service';
import {
    CoordinateSystem,
    CoordinateSystemUnits
} from 'src/app/shared/map-data-services/mapWorkspace/map-workspace.types';

import { MapMenuCode } from '../../map-viewer/map-menus/map-menu-list';
import { WorkspaceCreationMode, WorkspaceService } from '../workspace.service';

enum ZoneGroupComponentID {
    NETHERLANDS_RD = 'Trimble:69',
    UNITED_KINGDOM_ORDNANCE_SURVEY = 'Trimble:81'
}
const datumIDsToExcludeForUKAndNLGeographic = ['Trimble:4798', 'Trimble:246']; // RD 2018 (Netherlands) & Ordnance Survey
@Component({
    selector: 'workspace-coordinate-system',
    templateUrl: './workspace-coordinate-system.component.html'
})
export class WorkspaceCoordinateSystemComponent implements OnInit, OnForward, OnBackward, AfterViewInit, OnDestroy {
    @ViewChild('workspaceCoordinateSystemForm')
    public form: NgForm;

    public uiState = {
        backButton: { text: 'MapViewer.Generic.Previous', enabled: true, visible: true },
        forwardButton: { text: 'RESOLVED', enabled: false, visible: true },
        closeButton: { text: '', enabled: true, visible: true },
        cancelButton: { text: 'cancel', enabled: true, visible: true }
    };
    public modifyWorkspace: MapWorkspace;
    public filteredDatums: CoordinateSystemComponent[] = [];
    public horizontalUnits = coordinateSystemComponentUnits;
    public verticalUnits = coordinateSystemComponentUnits;
    public filteredZones: CoordinateSystemComponent[] = [];
    public filteredZoneGroups: CoordinateSystemComponent[] = [];
    public geoIds: CoordinateSystemComponent[] = [];
    public uploadedWorkspaceLayers: Layer[];
    public loading = true;
    public hasAnUnexpectedError = false;
    public workspaceCreationMode: WorkspaceCreationMode = WorkspaceCreationMode.NEW;
    public multipleHorizontalUnitsAllowed = true;
    public canadaDatumResourceUrl = CANADA_DATUM_RESOURCE_URL;

    // expose enum to template
    public NotificationType = NotificationType;
    public ModalSize = ModalSize;

    private canadianDatums = ['Trimble:4882', 'Trimble:4883', 'Trimble:4884'];
    private zoneGroups: CoordinateSystemComponent[] = [];
    private datums: CoordinateSystemComponent[] = [];
    private zones: CoordinateSystemComponent[] = [];
    private zoneToUnitMap: {
        [zoneComponentId: string]: { horizontalUnit: CoordinateSystemUnits; verticalUnit: CoordinateSystemUnits };
    };
    private center: LatLng = null;
    private readonly destroyed = new Subject<void>();

    constructor(
        private currentUserStream: CurrentUserStreamService,
        private wizardService: GspWizardService,
        private workspaceCreationService: WorkspaceService,
        private loaderStream: LoaderStreamService,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private layersStore: LayersStore,
        private layersStreams: LayersStreams,
        private router: Router,
        private mapWorkspaceService: MapWorkspaceService,
        private logger: GspLoggerService,
        private entitlementsStream: CurrentEntitlementStreamService
    ) {}

    ngOnInit(): void {
        this.workspaceCreationMode = this.workspaceCreationService.workspaceCreationMode$.getValue();
        if (this.workspaceCreationMode === WorkspaceCreationMode.DUPLICATE) {
            this.uiState.forwardButton.text = 'TC.Common.Next';
        }
        this.wizardService.setUI(this.uiState);
        this.modifyWorkspace = CloneUtils.cloneDeep(this.workspaceCreationService.modifyWorkspace$.getValue());
        const areaOfInterest = this.workspaceCreationService.workspaceAreaOfInterest$.getValue();
        this.center = areaOfInterest.center;

        // Japanese users should only see metric units
        if (this.currentUserStream.currentUser.locale === 'ja') {
            this.horizontalUnits = this.verticalUnits = coordinateSystemComponentUnits.filter(
                unit => unit.id === CoordinateSystemUnits.METRE
            );
        }

        const promises = [this.mapWorkspaceService.getComponents(this.center)];
        if (this.entitlementsStream.hasEntitlement(EntitlementSKUs.BETA_COORDS)) {
            promises.push(this.mapWorkspaceService.getComponents(this.center, CSComponentScopes.GEOSPATIAL_MAPSBETA));
        }

        Promise.all(promises)
            .then(responses => {
                const response = responses[1] ? responses[0].concat(responses[1]) : responses[0];
                this.datums = response.filter(item => item.type === CoordinateSystemComponentType.DATUM);
                this.zoneGroups = response.filter(item => item.type === CoordinateSystemComponentType.ZONE_GROUP);
                this.zones = response.filter(item => item.type === CoordinateSystemComponentType.ZONE);
                // Map zones to their corresponding unit defaults
                this.zoneToUnitMap = Object.fromEntries(
                    this.zones.map(zone => [
                        zone.componentID,
                        { horizontalUnit: zone.horizontalUnits, verticalUnit: zone.verticalUnits }
                    ])
                );
                this.geoIds = response.filter(item => item.type === CoordinateSystemComponentType.GEOID);
                this.filteredZoneGroups = this.getFilteredZoneGroups(this.zoneGroups, this.zones);
                this.filteredZoneGroups.push({
                    name: 'MapViewer.Workspace.CoordinateSystem.LatLng',
                    displayName: 'MapViewer.Workspace.CoordinateSystem.LatLng',
                    componentID: LATLONG_SYSTEM_COMPONENT_ID,
                    type: CoordinateSystemComponentType.ZONE_GROUP,
                    isGlobal: false,
                    parentID: null,
                    isDynamic: false,
                    localReferenceFrameComponentID: null
                });

                if (this.modifyWorkspace.coordinateSystem && this.modifyWorkspace.coordinateSystem.datumComponentId) {
                    this.setupOptionsFromPresetCoordinateSystem(
                        this.modifyWorkspace.coordinateSystem.zoneGroupComponentId
                    );
                    this.loading = false;
                    this.uiState.forwardButton.enabled = true;
                } else {
                    this.modifyWorkspace.coordinateSystem = new CoordinateSystem();
                    this.modifyWorkspace.coordinateSystem.geoidComponentId = this.geoIds.length
                        ? this.geoIds[0].componentID
                        : null;
                    this.modifyWorkspace.coordinateSystem.zoneGroupComponentId = this.filteredZoneGroups[0].componentID;
                    this.onZoneGroupChange();
                    this.loading = false;
                    this.uiState.forwardButton.enabled = true;
                }
            })
            .catch((e: HttpErrorResponse) => {
                this.logger.error(e.error);
                this.hasAnUnexpectedError = true;
            });
    }

    ngAfterViewInit(): void {
        this.form.control.statusChanges.pipe(takeUntil(this.destroyed), distinctUntilChanged()).subscribe(state => {
            this.uiState.forwardButton.enabled = state === 'VALID' ? true : false;
            this.wizardService.setUI(this.uiState);
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    public get aboveSeaLevel(): boolean {
        return GeneralUtils.isNullUndefinedOrNaN(this.modifyWorkspace.coordinateSystem.geoidComponentId) ? false : true;
    }

    public set aboveSeaLevel(value: boolean) {
        if (value) {
            this.modifyWorkspace.coordinateSystem.geoidComponentId = this.getDefaultGeoidComponentId();
        } else {
            this.modifyWorkspace.coordinateSystem.geoidComponentId = null;
        }
    }

    public closeErrorPopup(): void {
        this.workspaceCreationService.workspaceCreationOnClose();
    }

    public onBackward(): Promise<void> {
        this.workspaceCreationService.modifyWorkspace$.next(this.modifyWorkspace);
        return Promise.resolve();
    }

    public onForward(): Promise<void> {
        this.workspaceCreationService.modifyWorkspace$.next(this.modifyWorkspace);
        if (this.workspaceCreationMode === WorkspaceCreationMode.NEW) {
            return this.saveWorkspace();
        } else {
            return Promise.resolve();
        }
    }

    public onZoneChange(setDefaultUnits = true, updateGeoid = true): void {
        if (!this.modifyWorkspace.coordinateSystem.zoneComponentId) {
            this.setFilteredDatumsForGeographic();
        } else {
            this.setFilteredDatumsFromZone();
        }
        this.modifyWorkspace.coordinateSystem.datumComponentId = this.filteredDatums[0].componentID;
        this.onDatumChange(setDefaultUnits);
        if (updateGeoid) {
            this.modifyWorkspace.coordinateSystem.geoidComponentId = this.getDefaultGeoidComponentId();
        }
    }

    // Get zones based on the zone group/projection selected
    public onZoneGroupChange(): void {
        if (this.modifyWorkspace.coordinateSystem.zoneGroupComponentId === LATLONG_SYSTEM_COMPONENT_ID) {
            this.modifyWorkspace.coordinateSystem.zoneComponentId = null;
            this.filteredZones = [];
            this.aboveSeaLevel = false;
            this.onZoneChange(true, false);
        } else {
            this.filteredZones = this.zones.filter(
                zone => zone.parentID === this.modifyWorkspace.coordinateSystem.zoneGroupComponentId
            );
            this.modifyWorkspace.coordinateSystem.zoneComponentId = this.filteredZones[0].componentID;
            this.onZoneChange(true);
        }
    }

    // Filter out invalid zone Groups i.e. zone Groups without any zones available in a given location.
    private getFilteredZoneGroups(
        zoneGroups: CoordinateSystemComponent[],
        zones: CoordinateSystemComponent[]
    ): CoordinateSystemComponent[] {
        const filteredZoneGroups = zoneGroups.filter((zoneGroup: CoordinateSystemComponent) => {
            const childZones = zones.filter(zone => zone.parentID === zoneGroup.componentID);
            if (childZones && childZones.length) {
                return true;
            } else {
                return false;
            }
        });
        // Custom ordering when there are US County Zone Groups present.
        // Make sure US State Plane is the first option.
        const usCountyZoneGroups = Object.keys(usCountyUnitsDict);
        return filteredZoneGroups.find(zg => usCountyZoneGroups.includes(zg.componentID))
            ? filteredZoneGroups.sort((a, b) => (a.componentID === US_STATE_PLANE_COMPONENT_ID ? -1 : 0))
            : filteredZoneGroups;
    }

    private async saveWorkspace(): Promise<void> {
        this.loaderStream.isLoading$.next(true);
        let workspace: MapWorkspace;
        const uploadedWorkspace = this.workspaceCreationService.uploadWorkspaceTemplate$.getValue();
        try {
            if (uploadedWorkspace) {
                workspace = await this.mapWorkspacesStore.createNewMapWorkspaceFromFile(
                    this.modifyWorkspace,
                    uploadedWorkspace
                );
                this.uploadedWorkspaceLayers = await this.layersStore.loadMapWorkspaceLayers(workspace);
            } else {
                workspace = await this.mapWorkspacesStore.createNewMapWorkspace(this.modifyWorkspace, false);
            }
            if (workspace) {
                this.workspaceCreationService.clearWorkspace();
                let activeMenu = [];
                activeMenu.push(MapMenuCode.LAYERS);

                await this.router.navigate(['mapViewer', { outlets: { centerDialog: null, mainMenu: null } }], {
                    queryParams: {
                        projectId: workspace.projectId,
                        workspaceId: workspace.id,
                        activeMenu: MapMenuCode.LAYERS
                    }
                });

                if (uploadedWorkspace) {
                    this.layersStreams.setVisibleLayers(this.uploadedWorkspaceLayers, true);
                }
            }
        } finally {
            this.loaderStream.isLoading$.next(false);
        }
    }

    public onDatumChange(setDefaultUnits = false): void {
        this.modifyWorkspace.coordinateSystem.localReferenceFrameComponentId = this.filteredDatums.find(
            datum => datum.componentID === this.modifyWorkspace.coordinateSystem.datumComponentId
        ).localReferenceFrameComponentID;
        this.horizontalUnits = this.modifyWorkspace.getAllowedHorizontalUnits();
        if (setDefaultUnits) {
            const units = this.zoneToUnitMap[this.modifyWorkspace.coordinateSystem.zoneComponentId];
            this.modifyWorkspace.coordinateSystem.horizontalUnit =
                units && coordinateSystemComponentUnits.some(u => u.id === units.horizontalUnit)
                    ? units.horizontalUnit
                    : this.horizontalUnits[0].id;
            this.modifyWorkspace.coordinateSystem.verticalUnit =
                units && coordinateSystemComponentUnits.some(u => u.id === units.verticalUnit)
                    ? units.verticalUnit
                    : this.verticalUnits[0].id;
        }
        this.multipleHorizontalUnitsAllowed = this.modifyWorkspace.zoneGroupAllowsMultipleHorizontalUnits();
    }

    private setFilteredDatumsForGeographic(): void {
        const ukOrNLZoneGroup = !!this.zoneGroups.find(
            group =>
                group.componentID === ZoneGroupComponentID.UNITED_KINGDOM_ORDNANCE_SURVEY ||
                group.componentID === ZoneGroupComponentID.NETHERLANDS_RD
        );
        this.filteredDatums = ukOrNLZoneGroup
            ? this.datums.filter(datum => !datumIDsToExcludeForUKAndNLGeographic.includes(datum.componentID))
            : this.datums;
    }

    public isCanadianDatum(datumComponentId: string): boolean {
        return this.canadianDatums.includes(datumComponentId);
    }

    private setFilteredDatumsFromZone(): void {
        const selectedZone = this.filteredZones.find(
            zone => zone.componentID === this.modifyWorkspace.coordinateSystem.zoneComponentId
        );
        if (GeneralUtils.isNullUndefinedOrNaN(selectedZone.defaultDatumID)) {
            this.filteredDatums = this.datums;
        } else {
            this.filteredDatums = [this.datums.find(datum => datum.componentID === selectedZone.defaultDatumID)];
        }
    }

    private setupOptionsFromPresetCoordinateSystem(zoneGroupComponentId: string): void {
        if (zoneGroupComponentId === LATLONG_SYSTEM_COMPONENT_ID) {
            this.filteredZones = [];
            this.setFilteredDatumsForGeographic();
        } else {
            this.filteredZones = this.zones.filter(zone => zone.parentID === zoneGroupComponentId);
            this.setFilteredDatumsFromZone();
        }
        this.horizontalUnits = this.modifyWorkspace.getAllowedHorizontalUnits();
        this.multipleHorizontalUnitsAllowed = this.modifyWorkspace.zoneGroupAllowsMultipleHorizontalUnits();
    }

    private getDefaultGeoidComponentId(): string {
        const selectedZone = this.filteredZones.find(
            zone => zone.componentID === this.modifyWorkspace.coordinateSystem.zoneComponentId
        );

        const geoid = this.geoIds.find(geoid => geoid.componentID === selectedZone?.defaultGeoidID);

        return geoid?.componentID || this.geoIds[0].componentID;
    }
}
