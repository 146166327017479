import { FileType } from 'src/app/feature/export/export';
import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';

import { AttrValues } from '../common/feature-filter/feature-filter';
import { FieldTypeOption } from '../template-services/field';
import { GeoUserSettingsRequest, GeoUsersSettings } from './user-settings';

// TODO: move these consts to map service?
export const DEFAULT_LAT = 34.514478;
export const DEFAULT_LNG = -40.84716;
export const DEFAULT_ZOOM = 4;
export const DEFAULT_MAP_MODE = 'map';
export const DEFAULT_MAP_PROVIDER = 'trimble';

export interface ExportSettings {
    exportFormat: FileType;
    imagesAsHyperlinks: boolean;
    markAsExported: boolean;
    exportAllFormAttributes: boolean;
    useCodedChoiceDescription: boolean;
}

export interface UserMapWorkspaceFilter {
    userIds: string[];
    fromDate: Date;
    toDate: Date;
    unassignedUser: boolean;
    isExported: boolean;
    isNotExported: boolean;
    isPostProcessingDone: boolean;
    isPostProcessingPending: boolean;
    isPostProcessingFailed: boolean;
    isPostProcessingUnprocessed: boolean;
    selectedLayer: string;
    selectedField: string;
    selectedFieldTypeOption: FieldTypeOption;
    attrValues: AttrValues;
    customTags: string[];
    noCustomTags: boolean;
}

export class UserMapWorkspaceSetting {
    id: string;
    name: string;
    settingsName: string = null;
    type = 'mapWorkspace';
    mapWorkspaceId: string = null;
    showAllLayers = 0; // boolean
    visibleLayerIds: string[] = null;
    center: { lat: number; lng: number } = { lat: DEFAULT_LAT, lng: DEFAULT_LNG };
    filters: UserMapWorkspaceFilter = {
        userIds: [],
        fromDate: null,
        toDate: null,
        unassignedUser: null,
        isExported: false,
        isNotExported: false,
        isPostProcessingDone: false,
        isPostProcessingPending: false,
        isPostProcessingFailed: false,
        isPostProcessingUnprocessed: false,
        selectedLayer: null,
        selectedField: null,
        selectedFieldTypeOption: null,
        attrValues: null,
        customTags: null,
        noCustomTags: false
    };
    selectedFeatureIds: string[] = null;
    export: ExportSettings = null;
    setView = false;
    zoom = DEFAULT_ZOOM;
    mapProvider: string = DEFAULT_MAP_PROVIDER;
    mapMode: string = DEFAULT_MAP_MODE;

    /* File Viewer workspace settings */
    isFileViewer = false;
    fileViewerMapWorkspace: string = null;
    lastViewedConnectFileVersionIds: string[] = null;
    forceUpdate = false;

    constructor(mapWorkspaceId: string) {
        this.mapWorkspaceId = mapWorkspaceId;
        this.name = mapWorkspaceId;
        this.settingsName = UserMapWorkspaceSetting.getSettingsName(mapWorkspaceId);
    }

    static fromDTO(dto: GeoUsersSettings): UserMapWorkspaceSetting {
        let settings: UserMapWorkspaceSetting = null;
        if (dto) {
            settings = new UserMapWorkspaceSetting(dto.value.id);
            settings.id = dto.id;
            settings.name = dto.name;
            settings.settingsName = dto.settingsName;
            settings.type = dto.type;
            settings.mapWorkspaceId = dto.value.id;
            settings.showAllLayers = dto.value.showAllLayers;
            settings.visibleLayerIds = dto.value.visibleLayerIds;

            if (dto.value.export) {
                settings.export = dto.value.export;
            }

            if (dto.value.center) {
                settings.center = dto.value.center;
            }

            // tagsList & hasNoTags are old filters that were used to track exported & not exported filters in user settings.
            // Therefore we need to handle them
            if (dto.value.filters) {
                settings.filters = {
                    userIds: dto.value.filters.userIds ? dto.value.filters.userIds : [],
                    fromDate: dto.value.filters.fromDate ? dto.value.filters.fromDate : null,
                    toDate: dto.value.filters.toDate ? dto.value.filters.toDate : null,
                    isNotExported: !GeneralUtils.isNullUndefinedOrNaN(dto.value.filters.hasNoTags)
                        ? dto.value.filters.hasNoTags
                        : false,
                    isExported: dto.value.filters.tagsList && dto.value.filters.tagsList.length ? true : false,
                    unassignedUser: !GeneralUtils.isNullUndefinedOrNaN(dto.value.filters.unassignedUser)
                        ? dto.value.filters.unassignedUser
                        : null,
                    isPostProcessingDone: dto.value.filters.isPostProcessingDone || false,
                    isPostProcessingPending: dto.value.filters.isPostProcessingPending || false,
                    isPostProcessingFailed: dto.value.filters.isPostProcessingFailed || false,
                    isPostProcessingUnprocessed: dto.value.filters.isPostProcessingUnprocessed || false,
                    selectedLayer: dto.value.filters.selectedLayer || null,
                    selectedField: dto.value.filters.selectedField || null,
                    selectedFieldTypeOption: dto.value.filters.selectedFieldTypeOption || null,
                    attrValues: dto.value.filters.attrValues || null,
                    customTags: dto.value.filters.customTags || null,
                    noCustomTags: dto.value.filters.noCustomTags || false
                };

                settings.filters.isExported = !GeneralUtils.isNullUndefinedOrNaN(dto.value.filters.isExported)
                    ? dto.value.filters.isExported
                    : settings.filters.isExported;
                settings.filters.isNotExported = !GeneralUtils.isNullUndefinedOrNaN(dto.value.filters.isNotExported)
                    ? dto.value.filters.isNotExported
                    : settings.filters.isNotExported;
            }

            settings.selectedFeatureIds = dto.value.selectedFeatureIds;
            settings.setView = dto.value.setView;
            settings.zoom = dto.value.zoom;
            settings.mapProvider = dto.value.mapProvider;
            settings.mapMode = dto.value.mapMode;
            settings.isFileViewer = dto.value.isFileViewer;
            settings.fileViewerMapWorkspace = dto.value.fileViewerMapWorkspace;
            settings.lastViewedConnectFileVersionIds = dto.value.lastViewedConnectFileVersionIds;
            settings.forceUpdate = dto.value.forceUpdate;
        }

        return settings;
    }

    static getSettingsName(workspaceId: string): string {
        return 'mapWorkspace-' + workspaceId;
    }

    public toDTO(): GeoUserSettingsRequest {
        let settings: any = {};

        settings.id = this.mapWorkspaceId;
        const isFileViewer = this.isFileViewer;
        settings.isFileViewer = this.isFileViewer;

        if (isFileViewer) {
            // File viewer defaults
            settings.showAllLayers = true;
            settings.setView = false;
            settings.visibleLayerIds = [];
            settings.center = { lat: DEFAULT_LAT, lng: DEFAULT_LNG };
            settings.export = this.export;
            settings.filter = null;
            settings.selectedFeatureIds = null;
            settings.zoom = DEFAULT_ZOOM;
            settings.mapProvider = DEFAULT_MAP_PROVIDER;
            settings.mapMode = DEFAULT_MAP_MODE;
            settings.lastViewedConnectFileVersionIds = this.lastViewedConnectFileVersionIds;
            settings.forceUpdate = true;
            settings.fileViewerMapWorkspace = this.fileViewerMapWorkspace;
        } else {
            if (!GeneralUtils.isNullUndefinedOrNaN(this.showAllLayers)) {
                settings.showAllLayers = this.showAllLayers;
            }

            if (!GeneralUtils.isNullUndefinedOrNaN(this.setView)) {
                settings.setView = this.setView;
            }

            if (!GeneralUtils.isNullUndefinedOrNaN(this.visibleLayerIds)) {
                settings.visibleLayerIds = this.visibleLayerIds;
            }

            if (!GeneralUtils.isNullUndefinedOrNaN(this.center)) {
                settings.center = this.center;
            }

            if (!GeneralUtils.isNullUndefinedOrNaN(this.export)) {
                settings.export = this.export;
            }

            if (!GeneralUtils.isNullUndefinedOrNaN(this.filters)) {
                settings.filters = this.filters;
            }

            if (!GeneralUtils.isNullUndefinedOrNaN(this.selectedFeatureIds)) {
                settings.selectedFeatureIds = this.selectedFeatureIds;
            }

            if (!GeneralUtils.isNullUndefinedOrNaN(this.zoom)) {
                settings.zoom = this.zoom;
            }

            if (!GeneralUtils.isNullUndefinedOrNaN(this.mapProvider)) {
                settings.mapProvider = this.mapProvider;
            }

            if (!GeneralUtils.isNullUndefinedOrNaN(this.mapMode)) {
                settings.mapMode = this.mapMode;
            }

            settings.forceUpdate = false;
            settings.lastViewedConnectFileVersionIds = [];
            settings.fileViewerMapWorkspace = null;
        }

        return {
            name: this.name,
            type: this.type,
            settingsName: this.settingsName,
            value: settings
        };
    }
}
