import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Subject, switchMap, takeUntil, timer } from 'rxjs';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';
import { LayerService } from 'src/app/shared/map-data-services/layer/layer.service';

import { ImportApiResponse, ImportApiStatus, ImportFile, ImportStatusDict } from './import-file';

export enum IntervalTime {
    Quicker = 5000,
    Default = 10000,
    ClosedModal = 30000
}

@Injectable({
    providedIn: 'root'
})
export class ImportPollingStatusService {
    public importsState = new BehaviorSubject<ImportStatusDict>({});
    private pollInterval = new BehaviorSubject<IntervalTime>(IntervalTime.ClosedModal);

    constructor(
        private http: HttpClient,
        private environmentService: EnvironmentService,
        private layersStore: LayersStore,
        private layerService: LayerService,
        private messagingService: MessagingService,
        private translate: TranslationService
    ) {}

    public startImportPolling(projectId: string, workspaceId: string, destroyed: Subject<void>): void {
        this.pollInterval
            .pipe(
                switchMap(milliseconds => timer(0, milliseconds)),
                takeUntil(destroyed)
            )
            .subscribe(async () => {
                const inProgress = await this.getAllFeatureImportsInProgress(projectId, workspaceId);
                const importStatesList = this.importsState.getValue();

                inProgress.items.forEach(importItem => {
                    // Add new imports to dictionary if there's a file uploaded
                    if (importItem.importFileName && !importStatesList[importItem.importId]) {
                        this.updateImportState(importItem);
                    }
                });

                Object.keys(importStatesList).forEach(async stateId => {
                    const { status } = importStatesList[stateId];
                    const importStateIsInProgress =
                        status === ImportApiStatus.SUBMITTED || status === ImportApiStatus.INPROGRESS;

                    // Remove imports that are no longer in inProgress
                    if (importStateIsInProgress && !inProgress.items.find(item => item.importId === stateId)) {
                        const importApiResponse = await this.getImportById(projectId, stateId);
                        this.updateStatusDictFromApiResponse(importApiResponse, stateId);

                        if (importApiResponse.status === ImportApiStatus.FAILED) {
                            // leave the importState in Poll & show toast message
                            this.showErrorToast(importApiResponse);
                        } else {
                            this.reloadNewLayersAndRemoveFromPoll(projectId, importApiResponse);
                        }
                    }
                });
            });
    }

    private addToImportState(importItem: ImportApiResponse): void {
        if (!importItem) return;

        const { importId, status, importFileName, importedLayerIds } = importItem;
        const currentImportsState = this.importsState.getValue();
        currentImportsState[importId] = {
            ...currentImportsState[importId],
            status,
            fileName: importFileName,
            importedLayerIds
        } as ImportStatusDict[typeof importId];

        this.importsState.next({ ...currentImportsState });
    }

    public removeFromImportState(importId: string): void {
        const currentImportsState = this.importsState.getValue();
        delete currentImportsState[importId];
        this.importsState.next({ ...currentImportsState });
    }

    private async refreshImportFileFromLayerStore(projectId: string, layerId: string): Promise<void> {
        const layer = await this.layerService.getLayerById(projectId, layerId);
        this.layersStore.refreshLayerInStore(projectId, layer, true);
    }

    public updateImportState(importItem: ImportApiResponse): void {
        if (!importItem) return;

        if (importItem.status === ImportApiStatus.COMPLETED) {
            this.removeFromImportState(importItem.importId);
        } else {
            this.addToImportState(importItem);
        }
    }

    private updateStatusDictFromApiResponse(importApiResponse: ImportApiResponse, stateId: string): void {
        if (!stateId) return;

        const currentImportsState = this.importsState.getValue();
        const { status, importFileName, importedLayerIds } = importApiResponse;
        currentImportsState[stateId] = {
            ...currentImportsState[stateId],
            status,
            fileName: importFileName,
            importedLayerIds
        };

        this.importsState.next({ ...currentImportsState });
    }

    public updatePollInterval(newInterval: number): void {
        this.pollInterval.next(newInterval);
    }

    private reloadNewLayersAndRemoveFromPoll(projectId: string, importApiResponse: ImportApiResponse): void {
        if (!importApiResponse) return;

        const { status, importId, importedLayerIds } = importApiResponse;
        const hasImportedLayerIds = !!importedLayerIds?.length;

        if (hasImportedLayerIds) {
            importApiResponse.importedLayerIds.forEach(async layerId => {
                await this.refreshImportFileFromLayerStore(projectId, layerId);

                // remove from poll once completed, make sure to do this before refreshing per each layer
                if (status === ImportApiStatus.COMPLETED) {
                    this.removeFromImportState(importId);
                }
            });
        }
    }

    // show a toast message once
    private showErrorToast(importApiResponse: ImportApiResponse): void {
        this.messagingService.showError(
            this.translate.instant('MapViewer.Import.GenericError', {
                fileName: importApiResponse.importFileName
            })
        );
    }

    // all FeatureImport/ API calls below
    public getAllFeatureImportsInProgress(
        projectId: string,
        workspaceId: string
    ): Promise<{ items: ImportApiResponse[] }> {
        const featureImportApi =
            this.environmentService.apiUrl +
            '/projects/' +
            projectId +
            '/featureimport?workspaceId=' +
            workspaceId +
            '&fetchInprogressJobsOnly=true';
        return lastValueFrom(this.http.get<{ items: ImportApiResponse[] }>(featureImportApi));
    }

    public async postFeatureImportToMapsApi(
        importFile: ImportFile,
        projectId: string,
        workspaceId: string
    ): Promise<void> {
        const importData = {
            importId: importFile.id,
            importFileName: importFile.fileName,
            workspaceId,
            sourceLayerNames: importFile.layerNames,
            layerStyles: importFile.styles,
            isComplete: importFile.isComplete
        };

        await lastValueFrom(
            this.http.post(this.environmentService.apiUrl + '/projects/' + projectId + '/featureimport', importData)
        );
    }

    public getImportById(projectId: string, importId: string): Promise<ImportApiResponse> {
        const featureImportApi =
            this.environmentService.apiUrl + '/projects/' + projectId + '/featureimport/' + importId;
        return lastValueFrom(this.http.get<ImportApiResponse>(featureImportApi));
    }
}
